import React from 'react'
import Header from '../component/header'; 
import Footer from '../component/footer';
import BlogBanner from './Blog/BlogBanner';
import BlogBody from './Blog/BlogBody';
import BacktoTop from './HomeComponent/BacktoTop';
import { TopFooter } from './HomeComponent/TopFooter';

const Blog = () => {
  return (
  <>
  <BacktoTop />
  <Header />
  <BlogBanner />
<BlogBody />
<TopFooter/>
 <Footer />
  </>
  )
}

export default Blog;