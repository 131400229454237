/** @format */

import { useEffect, useState } from "react";
import signup from "../../asset/img/signup.png";
import Header from "../../component/header";
import Footer from "../../component/footer";
import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { isToaster } from "../../actions";
import $ from "jquery";
import {
  GetCountries,
  GetState,
  GetCity,
  // GetLanguages, //async functions
} from "react-country-state-city";

function Signup() {
  const [step, setStep] = useState(1);
  const [acountType, setAccountType] = useState("paid");
  const [isDisablePrevious, setIsDisablePrevious] = useState(false)
  const [zip, setZip] = useState("");
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentVerified, setPaymentVerified] = useState(0);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [isUsernameTaken, setUsernameTaken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [sendOtpClicked, setSendOtpClicked] = useState(false);
  const [showErr, setShowErr] = useState("");
  const [error, setError] = useState("");
  const [latitude,setLatitude]=useState()
  const [longitude,setLongitude]=useState()
  const [user, setUser] = useState({
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    otp: "",
    password: "",
    password_confirmation: "",
    age: 0,
    age_confirmed: 1,
    gender: "",
    payment_received: 1,
    plan: "Basic",
  });

  const ageData = [
    18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
    37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  ];
  const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  let name, Value;
  const handleInputs = (e) => {
    setError("");
    setShowErr("");
    name = e.target.name;
    Value = e.target.value;
    setUser({ ...user, [name]: Value });
  };

  const checkUsername = (e) => {
    const username = e.target.value;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/check-username?username=${username}`
      )
      .then((res) => {
        setUsernameTaken(false);
        // console.log(res.data.status);
      })
      .catch((err) => {
        // console.log(err.response.data.status)
        if (err.response.data.message == "Username already taken.") {
          setUsernameTaken(true);
          // dispatch(isToaster({ flag: true, msg: err.response.data.message }));
        } else {
          setUsernameTaken(false);
        }
      });
  };

  const sendOtp = () => {
    let postData = { email: user.email };
    setLoadingOtp(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/send-otp`, postData)
      .then((res) => {
        setLoadingOtp(false);
        if (res.status === 200) {
          dispatch(isToaster({ flag: true, msg: "OTP sent on your mail Id" }));
          setSendOtpClicked(true);
        }
        console.log(res.status);
      })
      .catch((err) => {
        setLoadingOtp(false);
        setSendOtpClicked(false);
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
      });
  };

  const checkFreeZone = () => {
    let data = {
      country: countriesList.find((x) => x.id === countryid)?.name || null,
      state: stateList.find((x) => x.id === stateid)
        ? stateList.find((x) => x.id === stateid).name
        : null,
      city: cityList.find((x) => x.id === cityid)
        ? cityList.find((x) => x.id === cityid).name
        : null,
      zip: zip,
    };
    if (!data.country) {
      dispatch(
        isToaster({
          flag: true,
          msg: "Please select country!",
        })
      );
    } else if (!data.state && stateList.length > 0) {
      dispatch(
        isToaster({
          flag: true,
          msg: "Please select state!",
        })
      );
    } else if (!data.city && cityList.length > 0) {
      dispatch(
        isToaster({
          flag: true,
          msg: "Please select city!",
        })
      );
    } else if (!data.zip) {
      dispatch(
        isToaster({
          flag: true,
          msg: "Please enter ZIP code!",
        })
      );
    } else if (data.zip < 3) {
      dispatch(
        isToaster({
          flag: true,
          msg: "ZIP code must have 3 or more digits!",
        })
      );
    } else {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/check-free-zone?country=${
            countriesList.find((x) => x.id === countryid)?.name || null
          }&state=${
            stateList.find((x) => x.id === stateid)
              ? stateList.find((x) => x.id === stateid).name
              : null
          }&city=${
            cityList.find((x) => x.id === cityid)
              ? cityList.find((x) => x.id === cityid).name
              : null
          }&zip=${zip}`
        )
        .then((res) => {
          setLoading(false);
          if (res.data.applicableForFree) {
            createAccount(true);
          } else {
            createAccount(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(isToaster({ flag: true, msg: err.response.data.message }));
        });
    }
  };

  const checkOtp = () => {
    setLoadingVerification(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/check-otp?email=${user.email}&otp=${user.otp}`
      )
      .then((res) => {
        setLoadingVerification(false);
        if (res.data.status) {
          setEmailVerified(true);
          dispatch(
            isToaster({ flag: true, msg: "Email successfully verified!" })
          );
          // return setStep(3);
        }
      })
      .catch((err) => {
        setLoadingVerification(false);
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
      });
  };


//   const checkPhone = () => {
//   console.log(user.phone);
//   setLoading(true);
//   axios
//     .get(`${process.env.REACT_APP_API_URL}/api/check-phone?phone=${user.phone}`)
//     .then((res) => {
//       setLoading(false);
//       if (res.data && res.data.status) {
//         createAccount(false);
//       }
//     })
//     .catch((err) => {
//       console.log('signup.js line no 218', err);
//       console.log('signup.js line no 218', err.response);
//       if (err.response && err.response.data) {
//         console.log('signup.js line no 218', err.response.data);
//         dispatch(isToaster({ flag: true, msg: err.response.data.message }));
//       } else {
//         // Handle unexpected errors
//         dispatch(isToaster({ flag: true, msg: 'An unexpected error occurred' }));
//       }
//       setLoading(false);
//     });
// };

const checkPhone = () => {
  console.log(user.phone);
  setLoading(true);
  
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/check-phone?phone=${user.phone}`)
    .then((res) => {
      if (res.data && res.data.status) {
        createAccount(false);
      }
    })
    .catch((err) => {
      console.error('Error in checkPhone:', err);
      const message = err.response?.data?.message || 'An unexpected error occurred';
      dispatch(isToaster({ flag: true, msg: message }));
    })
    .finally(() => {
      setLoading(false);
    });
};




  const createAccount = (isfree) => {
    let postData = {
      username: user.username,
      firstname: user.firstname,
      lastname: "dummy",
      phone: user.phone,
      email: user.email,
      otp: user.otp,
      password: user.password,
      password_confirmation: user.password_confirmation,
      age: user.age,
      age_confirmed: 1,
      gender: user.gender,
      payment_received: 0,
      plan: "",
      country: countriesList.find((x) => x.id === countryid)?.name || "",
      state: stateList.find((x) => x.id === stateid)
        ? stateList.find((x) => x.id === stateid).name
        : "",
      city: cityList.find((x) => x.id === cityid)
        ? cityList.find((x) => x.id === cityid).name
        : "",
      zip: zip,
      latitude:latitude,
      longitude:longitude
    };
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (isfree && step != 4) {
      setStep(4);
      // setLoading(true);
      // axios
      //   .post(
      //     `${process.env.REACT_APP_API_URL}/api/register`,
      //     postData,
      //     customConfig
      //   )
      //   .then((res) => {
      //     if (res.status === 200) {
      //       setLoading(false);
      //       navigate("/free-zone-message");
      //       dispatch(
      //         isToaster({
      //           flag: true,
      //           msg: `Hi ${user.firstname}, Your account is created Successfully!`,
      //         })
      //       );
      //       localStorage.removeItem("accountToCreate");
      //     } else {
      //       console.log(res);
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setError("afterAPI");
      //     dispatch(isToaster({ flag: true, msg: err.response.data.message }));
      //   });
    } else if (!isfree && step != 4) {
      navigate("/payment");
      console.log(postData,"newres");
      localStorage.setItem("accountToCreate", JSON.stringify(postData));
      // navigate("/payment");
    } else if (!isfree && step == 4 && acountType === "free") {
      console.log("free account");
      const postdata = JSON.parse(localStorage.getItem("accountToCreate"));
      postdata.age = user.age;
      postdata.phone = user.phone;
      postdata.gender = user.gender;
      postdata.lastname = "dummy";
      postdata.payment_received = 1;
      if (user.age && user.phone && user.gender) {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/register`,
            postdata,
            customConfig
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              navigate("/login");
              dispatch(
                isToaster({
                  flag: true,
                  msg: `Hi ${postdata.firstname}, Your account is created Successfully!`,
                })
              );
              localStorage.removeItem("accountToCreate");
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            setLoading(false);
            setError("afterAPI");
            dispatch(isToaster({ flag: true, msg: err.response.data.message }));
          });
      }
    } else if (step === 4) {
      const postdata = JSON.parse(localStorage.getItem("accountToCreate"));
      postdata.age = user.age;
      postdata.phone = user.phone;
      postdata.gender = user.gender;
      postdata.lastname = "dummy";
      postdata.payment_received = paymentVerified;
      console.log(paymentVerified);
      if (paymentVerified == 1 && user.age && user.phone && user.gender) {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/register`,
            postdata,
            customConfig
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              navigate("/login");
              dispatch(
                isToaster({
                  flag: true,
                  msg: `Hi ${postdata.firstname}, Your account is created Successfully!`,
                })
              );
              localStorage.removeItem("accountToCreate");
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            setLoading(false);
            setError("afterAPI");
            dispatch(isToaster({ flag: true, msg: err.response.data.message }));
          });
      } else {
        navigate("/payment");
        dispatch(isToaster({ flag: true, msg: "Payment is not Verified" }));
      }
    }
  };

  useEffect(() => {
    const usernameInput = document.getElementById("username");
    const firstnameInput = document.getElementById("firstname");
    const lastnameInput = document.getElementById("lastname");

    $(usernameInput).on("input", function () {
      var c = this.selectionStart,
        r = /[^a-z0-9]/gi,
        v = $(this).val();
      if (r.test(v)) {
        $(this).val(v.replace(r, ""));
        c--;
      }
      this.setSelectionRange(c, c);
    });

    $(firstnameInput).on("input", function () {
      var c = this.selectionStart,
        r = /[^a-z0-9]/gi,
        v = $(this).val();
      if (r.test(v)) {
        $(this).val(v.replace(r, ""));
        c--;
      }
      this.setSelectionRange(c, c);
    });

    $(lastnameInput).on("input", function () {
      var c = this.selectionStart,
        r = /[^a-z0-9]/gi,
        v = $(this).val();
      if (r.test(v)) {
        $(this).val(v.replace(r, ""));
        c--;
      }
      this.setSelectionRange(c, c);
    });
  }, []);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const [countryid, setCountryid] = useState(-1);
  const [stateid, setStateid] = useState(-1);
  const [cityid, setCityid] = useState(-1);

  const [countryindex, setCountryindex] = useState();
  const [stateindex, setStateindex] = useState();
  const [cityindex, setCityindex] = useState();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    // Fetch states when countryid changes
    if (countryid !== -1) {
      GetState(countryid).then((result) => {
        // console.log("stateList: ", result)
        setStateList(result);
      });
    }
  }, [countryid]);

  useEffect(() => {
    // Fetch cities when both countryid and stateid change
    if (countryid !== -1 && stateid !== -1) {
      GetCity(countryid, stateid).then((result) => {
        // console.log("CityList: ", result)
        setCityList(result);
      });
    }
  }, [countryid, stateid]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const step = urlParams.get("step");
    const success = urlParams.get("success");
    const trackId = urlParams.get("trackId");
    if (step == 4 && success == 0) {
      setStep(4);
      setAccountType("free");
    } else if (step == 4 && success == 1) {
      setStep(4);
      setIsDisablePrevious(true)
      checkPayments(trackId);
    } else {
      setStep(1);
    }
  }, []);

  const checkPayments = (trackId) => {
    const url = "https://api.oxapay.com/merchants/inquiry";
    const data = JSON.stringify({
      merchant: "sandbox",
      trackId: trackId,
    });

    axios
      .post(url, data)
      .then((response) => {
        if (response.data.result == 100) {
          const signupDetails = localStorage.getItem("accountToCreate");
          if (response.data.email == JSON.parse(signupDetails).email) {
            dispatch(isToaster({ flag: true, msg: "Payment is verified" }));
            setPaymentVerified(1);
          } else {
            dispatch(
              isToaster({
                flag: true,
                msg: "Payment is verified but not related to the person who is trying to signup",
              })
            );
            navigate("/");
          }
        } else {
          navigate("/payment");
          dispatch(
            isToaster({
              flag: true,
              msg: `Payment is not verified, pleae try again!`,
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showPassword = (a) => {
    let x = document.getElementById(a);
    if (x.type === "password") {
      x.type = "text";
    }
    setTimeout(() => {
      x.type = "password";
    }, 800);
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="form-layout">
                <div className="row p-3 d-flex align-items-center">
                  <div className="col-lg-6">
                    <img src={signup} alt="sing up" className="img-fluid" />
                  </div>
                  <div className="col-lg-6">
                    <div
                      className={`${
                        showErr === "error" ? "visible" : "invisible"
                      } text-red-600 text-center -mb-3`}
                    >
                      please provide a valid email !
                    </div>
                    <div
                      className={`${
                        showErr === "emailexist" ? "d-block" : "d-none"
                      } text-red-600 text-center -mb-3`}
                    >
                      Make sure Password has minimum length of 6 character!
                    </div>

                    <div
                      className={`${
                        error === "afterAPI" ? "d-block" : "d-none"
                      } text-red-600 text-center -mb-3`}
                    >
                      {showErr}
                    </div>

                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        className="step1"
                        style={{ display: `${step === 1 ? "block" : "none"}` }}
                      >
                        <div className="singinform p-lg-4">
                          <h4>
                            BASIC<span> INFO</span>
                          </h4>
                          <div className="inputField1 inputField1top ">
                            <input
                              type="text"
                              id="username"
                              name="username"
                              className="form-control form-control-ctm "
                              value={user.username}
                              onChange={(e) => {
                                handleInputs(e);
                                checkUsername(e);
                              }}
                              placeholder=""
                            />

                            <span>User Name</span>
                          </div>
                          <p
                            className={`float-end mb-2 ${
                              !isUsernameTaken ? "d-none" : null
                            }`}
                          >
                            <span
                              className={`underline`}
                              // onClick={checkOtp}
                              // disabled={loadingVerification}
                            >
                              {`${"Username is already taken"}`}
                            </span>
                          </p>
                          <p>
                            {" "}
                            <div className="inputField1 inputField1top mt-4">
                              <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                className="form-control form-control-ctm "
                                value={user.firstname}
                                onChange={handleInputs}
                                placeholder=""
                              />
                              <span>Working name</span>
                            </div>
                          </p>
                          {/* <div className="inputField1 inputField1top">
                            <input
                              type="text"
                              id="lastname"
                              name="lastname"
                              className="form-control form-control-ctm my-3"
                              value={user.lastname}
                              onChange={handleInputs}
                              placeholder=" "
                            />
                            <span>Last Name</span>
                          </div> */}
                          <button
                            className="btn sub-btn  next "
                            onClick={() => {
                              if (!user.username || !user.firstname) {
                                dispatch(
                                  isToaster({
                                    flag: true,
                                    msg: "Please fill all details to proceed!",
                                  })
                                );
                              } else if (isUsernameTaken) {
                                dispatch(
                                  isToaster({
                                    flag: true,
                                    msg: "Please choose another username",
                                  })
                                );
                              } else {
                                setStep(2);
                              }
                            }}
                          >
                            Next Step
                          </button>
                        </div>
                      </div>

                      <div
                        className="step2"
                        style={{ display: `${step === 2 ? "block" : "none"}` }}
                      >
                        <div className="singinform p-lg-4">
                          <h4>
                            ACCOUNT <span>INFO</span>
                          </h4>
                          <div className="inputField1 inputField1top">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              className="form-control form-control-ctm mt-3"
                              value={user.email}
                              onChange={handleInputs}
                              placeholder=" "
                            />
                            <span>Email</span>
                          </div>
                          <p
                            className={`float-end mb-2 ${
                              isEmailVerified ? "d-none" : null
                            }`}
                          >
                            <span
                              className={`underline`}
                              onClick={sendOtp}
                              disabled={loadingOtp}
                            >
                              {`${sendOtpClicked ? "Resend OTP" : "Send OTP"}`}{" "}
                              &nbsp;
                              {loadingOtp ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                            </span>
                          </p>
                          <div
                            className={`inputField1  inputField1top mt-4 ${
                              sendOtpClicked ? null : "d-none"
                            }`}
                          >
                            <input
                              type="text"
                              id="otp"
                              name="otp"
                              className={`form-control form-control-ctm  ${
                                sendOtpClicked
                                  ? "hover:cursor-text"
                                  : "disabled"
                              }`}
                              disabled={!sendOtpClicked}
                              value={user.otp}
                              onChange={handleInputs}
                              placeholder=" "
                            />
                            <span>OTP</span>
                          </div>
                          <p
                            className={`float-end mb-2 ${
                              isEmailVerified || !sendOtpClicked
                                ? "d-none"
                                : null
                            }`}
                          >
                            <span
                              className={`underline`}
                              onClick={checkOtp}
                              disabled={loadingVerification}
                            >
                              {`${"Verify Email"}`}
                              &nbsp;
                              {loadingVerification ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                            </span>
                          </p>
                          {/* <div
                            className={`${
                              showErr === "otpsent" ? "d-block" : "d-none"
                            } text-red-600 text-center mt-0`}
                          >
                            OTP sent to your mail Id
                          </div> */}
                          <div
                            className={`inputField1 inputField1top input-top-sign ${
                              isEmailVerified ? null : "d-none"
                            }`}
                          >
                            <input
                              type="password"
                              id="password"
                              name="password"
                              className={`form-control form-control-ctm my-3 ${
                                sendOtpClicked
                                  ? "hover:cursor-text"
                                  : "disabled"
                              }`}
                              value={user.password}
                              disabled={!sendOtpClicked}
                              onChange={handleInputs}
                              placeholder=" "
                            />
                            <span>Password</span>
                            <div
                              className="eye-icon"
                              onClick={() => showPassword("password")}
                            >
                              <i className="fa fa-eye"></i>
                            </div>
                          </div>
                          <div
                            className={`inputField1 inputField1top input-top-sign ${
                              isEmailVerified ? null : "d-none"
                            }`}
                          >
                            <input
                              type="password"
                              id="password_confirmation"
                              name="password_confirmation"
                              className={`form-control form-control-ctm my-3 ${
                                sendOtpClicked
                                  ? "hover:cursor-text"
                                  : "disabled"
                              }`}
                              value={user.password_confirmation}
                              disabled={!sendOtpClicked}
                              onChange={handleInputs}
                              placeholder="      "
                            />
                            <span>Confirm Password</span>
                            <div
                              className="eye-icon"
                              onClick={() => showPassword("password_confirmation")}
                            >
                              <i className="fa fa-eye"></i>
                            </div>
                          </div>
                          <div className="register-btn mt-5">
                            <button
                              className="btn sub-btn  prev"
                              onClick={() => setStep(1)}
                            >
                              Prev Step
                            </button>
                            <button
                              className={`btn register-btn-m sub-btn next ${
                                sendOtpClicked &&
                                user.password &&
                                user.password_confirmation &&
                                user.otp
                                  ? "hover:cursor-pointer"
                                  : "disabled "
                              }`}
                              onClick={() => {
                                if (
                                  sendOtpClicked &
                                    (user.password ===
                                      user.password_confirmation) &&
                                  user.password.length >= 8
                                ) {
                                  setShowErr("");
                                  setStep(3);
                                  // return checkOtp();
                                } else if (
                                  sendOtpClicked &
                                    (user.password ===
                                      user.password_confirmation) &&
                                  user.password.length < 8
                                ) {
                                  dispatch(
                                    isToaster({
                                      flag: true,
                                      msg: "Password must be 8 digit",
                                    })
                                  );
                                }else if ( !passwordCheck.test(user.password)) {
                                  dispatch(
                                    isToaster({
                                      flag: true,
                                      msg: "Password must contain at least one number, one symbol, one uppercase letter, and be at least 8 characters long.",
                                    })
                                  );
                                } else {
                                  dispatch(
                                    isToaster({
                                      flag: true,
                                      msg: "Please make sure Password and Confirm-password are same!",
                                    })
                                  );
                                }
                              }}
                            >
                              Next Step &nbsp;
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="step3"
                        style={{ display: `${step === 3 ? "block" : "none"}` }}
                      >
                        <div className="singinform p-lg-4">
                          <h4>
                            YOUR <span>ADDRESS</span>
                          </h4>
                          <div className="input-label ">
                            <label className="mb-1" style={{ float: "left", color: "#fff" }}>
                              <span >Country</span>
                            </label>
                            <select
                              className="form-control form-select-ctm"
                              aria-label="select"
                              name="country"
                              onChange={(e) => {
                                const country = countriesList[e.target.value];
                                setCountryid(country.id);
                              }}
                              value={countryindex}
                              // placeholder="Country"
                            >
                              <option desabled selected>
                                
                              </option>
                              {countriesList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="input-label mt-2">
                            <label className="mb-1" style={{ float: "left", color: "#fff" }}>
                              <span>State</span>
                            </label>
                            <select
                              className="form-control form-select-ctm"
                              aria-label="select"
                              name="state"
                              onChange={(e) => {
                                const state = stateList[e.target.value];
                                setStateid(state.id);
                              }}
                              value={stateindex}
                              // placeholder="State"
                            >
                              <option desabled selected>
                                
                              </option>
                              {stateList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="input-label mt-2">
                            <label className="mb-1" style={{ float: "left", color: "#fff" }}>
                              <span>City</span>
                            </label>
                            <select
                              className="form-control form-select-ctm"
                              aria-label="select"
                              name="city"
                              onChange={(e) => {
                                const city = cityList[e.target.value];
                                setLatitude(city.latitude)
                                setLongitude(city.longitude)

                                setCityid(city.id);
                              }}
                              value={cityindex}
                              // placeholder="City"
                            >
                              <option desabled selected>
                                
                              </option>
                              {cityList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="inputField1 inputField1top input-top-sign">
                            <input
                              type="number"
                              id="ZipCode"
                              name="ZipCode"
                              className="form-control form-control-ctm my-3"
                              value={zip}
                              onChange={(e) => setZip(e.target.value)}
                              placeholder=""
                            />
                            <span>ZipCode</span>
                          </div>

                          <div className="register-btn mt-3">
                            <button
                              className="btn sub-btn  prev"
                              onClick={() => setStep(2)}
                            >
                              Prev Step
                            </button>
                            <button
                              className="btn sub-btn next register-btn-m"
                              disabled={loading}
                              onClick={checkFreeZone}
                            >
                              Next Step &nbsp;
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="step4"
                        style={{ display: `${step === 4 ? "block" : "none"}` }}
                      >
                        <div className="singinform p-lg-4">
                          <h4>
                            ACCOUNT <span>INFO</span>
                          </h4>
                          <div className="inputField1 inputField2top">
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              className="form-control form-control-ctm my-2"
                              value={user.phone}
                              onChange={handleInputs}
                              placeholder=""
                            />
                            <span>Phone Number</span>
                          </div>
                          <div className="input-label ">
                            <label id="age-label"
                              className={`mb-1 ${
                                user.age ? "active" : ""
                              }`} 
                              style={{ float: "left", color: "#fff", fontSize:"0.86rem" }}
                              >
                              <span>Age</span>
                            </label> 
                          
                            <select
                              className="form-select-ctm my-2"
                              name="age"
                              value={user.age}
                              onChange={(e) =>
                                setUser({ ...user, age: e.target.value })
                              }
                            >
                              <option value="" selected>
                                -Age-
                              </option>
                              {ageData.map((x) => (
                                <option key={x} value={x}>
                                  {x}
                                </option>
                              ))}
                            </select>
                            
                          </div>

                          <p className="text-start mt-2 mb-0">Gender </p>
                          <div className="d-flex" name="gender">
                            <div className="form-check d-flex align-items-center">
                              <input
                                className="form-check-input form-check-input-ctm my-auto"
                                type="radio"
                                name="gender"
                                id="male"
                                checked={user.gender === "Male"}
                                value="Male"
                                onChange={handleInputs}
                              />
                              <label
                                className="form-check-label ms-1"
                                htmlFor="male"
                              >
                                Male
                              </label>
                            </div>
                            <div className="form-check  d-flex align-items-center ms-2">
                              <input
                                className="form-check-input form-check-input-ctm my-auto"
                                type="radio"
                                name="gender"
                                id="female"
                                checked={user.gender === "Female"}
                                value="Female"
                                onChange={handleInputs}
                              />
                              <label
                                className="form-check-label  ms-1"
                                htmlFor="female"
                              >
                                Female
                              </label>
                            </div>
                            <div className="form-check  d-flex align-items-center ms-2">
                              <input
                                className="form-check-input form-check-input-ctm my-auto"
                                type="radio"
                                name="gender"
                                id="Non-Binary"
                                checked={user.gender === "Non-Binary"}
                                value="Non-Binary"
                                onChange={handleInputs}
                              />
                              <label
                                className="form-check-label  ms-1"
                                htmlFor="Non-Binary"
                              >
                                Non-Binary
                              </label>
                            </div>
                            <div className="form-check d-flex align-items-center ms-2">
                              <input
                                className="form-check-input form-check-input-ctm my-auto"
                                type="radio"
                                name="gender"
                                id="other"
                                checked={user.gender === "other"}
                                value="other"
                                onChange={handleInputs}
                              />
                              <label
                                className="form-check-label  ms-1"
                                htmlFor="other"
                              >
                                Other
                              </label>
                            </div>
                          </div>

                          <div className="register-btn mt-3">
                            <button
                              className="btn sub-btn prev"
                              disabled
                              onClick={() => setStep(3)}
                            >
                              Prev Step
                            </button>
                            <button
                              className="btn sub-btn next register-btn-m"
                              disabled={loading}
                              onClick={() => {
                                if (!user.phone) {
                                  dispatch(
                                    isToaster({
                                      flag: true,
                                      msg: "Please enter phone number!",
                                    })
                                  );
                                } else if (user.phone.length != 10) {
                                  dispatch(
                                    isToaster({
                                      flag: true,
                                      msg: "Phone number should be of 10 digit!",
                                    })
                                  );
                                } else if (!user.age) {
                                  dispatch(
                                    isToaster({
                                      flag: true,
                                      msg: "Please select age!",
                                    })
                                  );
                                } else if (!user.gender) {
                                  dispatch(
                                    isToaster({
                                      flag: true,
                                      msg: "Please select gender!",
                                    })
                                  );
                                } else {
                                  return checkPhone();
                                }
                              }}
                            >
                              Next Step &nbsp;
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Signup;
