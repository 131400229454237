import React, { useEffect, useState } from "react";
import plan from "../../../src/asset/img/crown.png";
import { Link } from "react-router-dom";
import service from "../../../src/asset/img/service.png";
import cplan from "../../../src/asset/img/cplan.png";
import { useDispatch, useSelector } from "react-redux";
import { isToaster } from "../../actions";
import axios from "axios";
import { isLoader } from "../../actions";

const Plan = () => {
  const data = useSelector((state) => state.user_data);
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [hoveredPlan, setHoveredPlan] = useState(null);
  let token = localStorage.getItem("userToken");

  const getPlans = () => {
    dispatch(isLoader(true));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-plans`)
      .then((res) => {
        dispatch(isLoader(false));
        setPlans(res.data.plans);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.error("Error fetching plans:", err);
      });
  };


  const handleUpdatePlan = async (planType, amount) => {
    console.log(planType);
  
    const postData = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/plan/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ plan: planType }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to update plan: ${response.status}`);
        // dispatch(isToaster({ flag: true, msg: updatePlanData.message }));
      }
  
      const updatePlanData = await response.json();
      console.log("Plan updated successfully:", updatePlanData);
      if(updatePlanData && planType=='Free'){
        dispatch(isToaster({ flag: true, msg: updatePlanData.message }));
        window.location.reload()
      }

    } catch (error) {
      console.error("Error updating plan:", error);
      return; 
    }


    if (planType =='Premium'|| planType=='Standard'||planType =='Basic') {
      console.log(planType)
      const postData = JSON.parse(localStorage.getItem("userData"));
      try {
        const response = await fetch("https://api.oxapay.com/merchants/request", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            merchant: 'sandbox',
            amount: amount,
            currency: "USD",
            email: postData.email,
            lifeTime: 15,
            feePaidByPayer: 0,
            underPaidCover: 0,
            // returnUrl: "http://localhost:3000/payment-details",
            returnUrl: `${process.env.REACT_APP_HOME_URL}`,
            description: planType,
            orderId: "ORD-12345",
          }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const responseData = await response.json();
        if (responseData && responseData.payLink) {
          window.location.href = responseData.payLink;
        } else {
          console.error("Invalid response data:", responseData);
        }
      } catch (error) {
        console.error("Error creating payment link:", error);
      }
    }
  };
  
  
  
  useEffect(() => {
    getPlans();
  }, []);

  return (
    <section className="addash">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex align-items-center">
              <h4>View Plan</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="plan">
              <div className="d-flex align-items-center">
                <img src={plan} alt="plan" className="img-fluid" />
                <span className="mx-4 plan-text">
                  <p className="m-0">
                    Your active package is -{" "}
                    <span className="plan-detail">{data.plan}</span> -{" "}
                    <span>Expire: 365 Days</span>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          {plans.map((x, i) => (
            <div
              key={i}
              className="col-sm-6 col-md-6 col-lg-3"
              onMouseEnter={() => setHoveredPlan(x.type)}
              onMouseLeave={() => setHoveredPlan(null)}
            >
              <div className="single_price_plan wow fadeInUp" data-wow-delay="0.2s">
                <div className="single_price_header">
                  <div className="single_price_header-img">
                    <img src={service} alt="planicon" className="img-fluid" />
                  </div>
                  <div className="title">
                    <h3>{x.type}</h3>
                  </div>
                </div>
                <div className="price">
                  <h4>{x.amount > 0 ? x.amount : 0} USD</h4>
                  <span>/ Month</span>
                  <div className="line"></div>
                </div>
                <div className="plan-description">
                  <p>
                    <i className="fa-regular fa-circle-check"></i>Add {x.total_photos} Photos
                  </p>
                  <p>
                    <i className="fa-regular fa-circle-check"></i>Add {x.total_videos} Videos
                  </p>
                  <p>
                    <i className="fa-regular fa-circle-xmark lni-close"></i>No Hidden Fees
                  </p>
                </div>
                <div className="button-plan">
                  {data.plan === x.type ? (
                    <div>
                      <img src={cplan} alt="" className="img-fluid" />
                    </div>
                  ) : hoveredPlan === x.type ? (
                    <button className="update-plan-btn" onClick={() => handleUpdatePlan(x.type, x.amount)}>
                      Update Plan
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Plan;

