import React, { useState } from "react";
import axios from "axios";

const DeleteNotification = ({ deleteId, onDelete, onClose }) => {
    const [confirmText, setConfirmText] = useState("");
console.log(deleteId)
const id ={
    id:deleteId
}
    const handleDelete = async () => {
            try {
                await axios.post(`https://socialmedia.quickvisit.me/api/notification/delete`,id);
                onDelete(deleteId); // Notify parent to remove the notification
                onClose(); // Close the modal
            } catch (error) {
                console.error("Error deleting notification:", error);
            }
        
    };

    return (
        <div className={`modal_container text-center m-auto ${deleteId ? "d-block" : "d-none"}`}>
            <div className="modal-dialog">
                <div className="modal-content price-table p-4">
                    <div className="position-relative">
                        <h2 className="modal-title fs-5 text-body" id="deletepopupLabel">
                            Delete this?
                        </h2>
                        <span className="position-absolute top-0 end-0">
                            <i className="fa-solid fa-x" onClick={onClose}></i>
                        </span>
                    </div>
                    <div className="modal-body">
                        <h2 className="fs-6 text-body">Are you sure to delete this id :{deleteId}?</h2>
                       
                    </div>
                    <div className="p-2">
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn cus-btn" onClick={handleDelete}>
                                Delete
                            </button>
                            <button type="button" className="btn sec-btn ms-3" onClick={onClose}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteNotification;
