// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { advertisementId, advertiserId, allList1, allList2, allList3, isLoader } from "../../actions";
// import gallery from "../../asset/img/gallery.png";
// import { useDispatch, useSelector } from "react-redux";
// import handleLastActive from "../../component/handleLastActive.js";
// import { Link, useNavigate } from "react-router-dom";

// const AllAdvertisements = () => {
//   // const [topList, setTopList] = useState([]);
//   const [longitude , setLongitude]=useState("")
//   const [latitude, setLatitude]=useState("")
//   const search_gender = useSelector((s) => s.search_gender);
//   const search_city = useSelector((s) => s.search_city);
//   const search_age = useSelector((s) => s.search_age);
//   const search_eye_color = useSelector((s) => s.search_eye_color);
//   const search_hair_color = useSelector((s) => s.search_hair_color);
//   const search_height = useSelector((s) => s.search_height);
//   const search_body_type = useSelector((s) => s.search_body_type);
//   const search_breast_size = useSelector((s) => s.search_breast_size);
//   const search_ethnicity = useSelector((s) => s.search_ethnicity);
//   const search_availability = useSelector((s) => s.search_availability);

//   const topList = useSelector((s) => s.all_list2);
//   const advertiserCity = localStorage.getItem("advertiserCity");
//   let dispatch = useDispatch();
//   let navigate = useNavigate();

//   const fetchAllAds = async() => {
//     dispatch(isLoader(true));
//     await axios
//       .get(
//         `${process.env.REACT_APP_API_URL}/api/get-all-advertisements-home?defaultCity=${advertiserCity}&searchedCity=${search_city}&eye_color=${search_eye_color}&hair_color=${search_hair_color}&breast_size=${search_breast_size}&height=${search_height}&ethnicity=${search_ethnicity}&age=${search_age}&body_type=${search_body_type}&gender=${search_gender}&latitude=${latitude}&longitude=${longitude}`
//       )
//       .then((res) => {
//         dispatch(isLoader(false));
//         console.log(res,"res")

//         // console.log(res.data.topPrimaryAdvertisements.data)
//         // setTopList(res.data.topPrimaryAdvertisements.data);
//         dispatch(allList2(res.data.allAdvertisements.data));
//       })
//       .catch((err) => {
//         dispatch(isLoader(false));
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     if (topList.length == 0) {
//       fetchAllAds();
//     } else {
//       fetchAllAds();
//     }
//   }, [search_gender, search_city, advertiserCity,longitude,latitude]);
//   useEffect(() => {
//     const longitude = localStorage.getItem("longitude");
//     const latitude = localStorage.getItem("latitude");
  
//     if (longitude) {
//       setLongitude(longitude);
//     }
//     if (latitude) {
//       setLatitude(latitude);
//     }
//   }, []);
//   return (
//     <>
//       <section className="spacing-page">
//         <div className="container">
//           <div className="row section-detail">
//             <div className="d-flex justify-content-between align-items-center p-0">
//               <h4 className="section-infoedit m-0">
//                 All <span>Advertisements</span>{" "}
//               </h4>
//               <Link to="/all-advertisers">
//                 <span className="btn cus-btn">View All</span>
//               </Link>
//             </div>

//             <span className="section-detail-border"></span>
//           </div>
//           <div className="row ads-lists mt-4">
//             {topList
//               ? topList.map((x) => {
//                   return (
//                     <div className="col-lg-3 col-md-3 col-6 mb-4 ">
//                       <div
//                         className="adscard-link"
//                         onClick={() =>
//                           navigate("/view-profile") &
//                           dispatch(advertiserId(x.advertiser_id)) &
//                           dispatch(advertisementId(x.ad_id))
//                         }
//                       >
//                         <div className="adscard">
//                           <div className="ads-card-body">
//                             <img src={x.profile_photo} alt="adsimage" />
//                             <div className="ads-photo">
//                               <span className="ads-gallery">
                              
//                                 <i
//                                   class="fa-regular fa-images"
//                                   aria-hidden="true"
//                                 ></i>
//                                 {x.advertiser_photos_count}
//                               </span>
//                               <span className="ads-gallery">
//                                 {x.duration_price[0].price} $
//                               </span>
//                             </div>
//                             <div className="ads-card-body-footer">
//                               <h5>
//                                 <span>{x.username}</span>
//                                 {x.age}
//                               </h5>
//                               <p className="adss-footer">
//                                 <i className="fa  fa-location-dot"></i>
//                                 <span>{x.location}</span>
//                               </p>
//                               <p className="adss-footer">
//                                   {handleLastActive(
//                                     x.last_active
//                                       ? x.last_active
//                                       : "2024-04-22 erge"
//                                   )}
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   );
//                 })
//               : null}
//           </div>
//           <div className="row mt-5">
//             <div className="col-lg-12 text-center">
//               <Link to="/all-advertisers">
//                 {" "}
//                 <button type="button" className="btn cus-btn">
//                   View All
//                 </button>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default AllAdvertisements;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { advertisementId, advertiserId, allList2, isLoader } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import handleLastActive from "../../component/handleLastActive.js";
import { Link, useNavigate } from "react-router-dom";

const AllAdvertisements = () => {
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  
  const search_gender = useSelector((s) => s.search_gender);
  const search_city = useSelector((s) => s.search_city);
  const search_age = useSelector((s) => s.search_age);
  const search_eye_color = useSelector((s) => s.search_eye_color);
  const search_hair_color = useSelector((s) => s.search_hair_color);
  const search_height = useSelector((s) => s.search_height);
  const search_body_type = useSelector((s) => s.search_body_type);
  const search_breast_size = useSelector((s) => s.search_breast_size);
  const search_ethnicity = useSelector((s) => s.search_ethnicity);
  
  const topList = useSelector((s) => s.all_list2);
  const advertiserCity = localStorage.getItem("advertiserCity");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllAds = async () => {
    dispatch(isLoader(true));
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-all-advertisements-home?defaultCity=${advertiserCity}&searchedCity=${search_city}&eye_color=${search_eye_color}&hair_color=${search_hair_color}&breast_size=${search_breast_size}&height=${search_height}&ethnicity=${search_ethnicity}&age=${search_age}&body_type=${search_body_type}&gender=${search_gender}&latitude=${latitude}&longitude=${longitude}`
      );
      dispatch(isLoader(false));
      dispatch(allList2(res.data.allAdvertisements.data));
    } catch (err) {
      dispatch(isLoader(false));
      console.log(err);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error obtaining location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getLocation(); // Request location on mount
  }, []);

  useEffect(() => {
    fetchAllAds(); // Fetch ads when location or search criteria change
  }, [search_gender, search_city, advertiserCity, longitude, latitude]);

  return (
    <section className="spacing-page">
      <div className="container">
        <div className="row section-detail">
          <div className="d-flex justify-content-between align-items-center p-0">
            <h4 className="section-infoedit m-0">
              All <span>Advertisements</span>{" "}
            </h4>
            <Link to="/all-advertisers">
              <span className="btn cus-btn">View All</span>
            </Link>
          </div>

          <span className="section-detail-border"></span>
        </div>
        <div className="row ads-lists mt-4">
          {topList.map((x) => (
            <div className="col-lg-3 col-md-3 col-6 mb-4" key={x.ad_id}>
              <div
                className="adscard-link"
                onClick={() => {
                  navigate("/view-profile");
                  dispatch(advertiserId(x.advertiser_id));
                  dispatch(advertisementId(x.ad_id));
                }}
              >
                <div className="adscard">
                  <div className="ads-card-body">
                    <img src={x.profile_photo} alt="adsimage" />
                    <div className="ads-photo">
                      <span className="ads-gallery">
                        <i className="fa-regular fa-images" aria-hidden="true"></i>
                        {x.advertiser_photos_count}
                      </span>
                      <span className="ads-gallery">
                        {x.duration_price[0].price} $
                      </span>
                    </div>
                    <div className="ads-card-body-footer">
                      <h5>
                        <span>{x.username}</span>
                        {x.age}
                      </h5>
                      <p className="adss-footer">
                        <i className="fa fa-location-dot"></i>
                        <span>{x.location}</span>
                      </p>
                      <p className="adss-footer">
                        {handleLastActive(
                          x.last_active ? x.last_active : "2024-04-22 erge"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-5">
          <div className="col-lg-12 text-center">
            <Link to="/all-advertisers">
              <button type="button" className="btn cus-btn">
                View All
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllAdvertisements;
