import React from "react";
import about from "../../asset/img/aboutimage.png";

const OnlineService = () => {
  return (
    <>
      <section className="spacing-page" id="online-service">
        <div className="container">
          <div className="row section-detail">
            <div className="d-flex justify-content-between p-0">
              <h4 className="section-infoedit m-0">
                {" "}
                Online <span>Service</span>{" "}
              </h4>
              <span>
                <div className="carousel-btn">
                  <a
                    className="carousel-btn-pre"
                    href="#premiumslider"
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-btn-next"
                    href="#serviceslider"
                    role="button"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </span>
            </div>
            <span className="section-detail-border"></span>
          </div>
          <div id="serviceslider" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators premium-carousel-dot">
              <li
                data-target="#serviceslider"
                data-bs-slide-to="0"
                className="active me-1"
              ></li>
              <li data-bs-target="#serviceslider" 
                className="me-1" data-bs-slide-to="1"></li>
              <li data-bs-target="#serviceslider"  className="me-1" data-bs-slide-to="2"></li>
            </ol>
            <div className="carousel-inner p-0">
              <div className="carousel-item active">
                <div className="about-section">
                  <div className="row d-flex align-items-center mt-4">
                    <div className="col-lg-6 col-md-6 text-center">
                      <img src={about} alt="about" className="img-fluid" />
                    </div>
                    <div className="col-lg-6 col-md-6 service-detail">
                      <h2 className="service-detail-h m-0">
                        Lorium Ipsum <span>Dummy Text</span>{" "}
                      </h2>
                      <p className="mt-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </p>
                      <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs.
                      </p>
                      <p className="mt-4">
                        <button type="button" className="btn cus-btn">
                          Read More
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row d-flex align-items-center mt-4">
                  <div className="col-lg-6 col-md-6 ">
                    <img src={about} alt="about" className="img-fluid" />
                  </div>
                  <div className="col-lg-6 col-md-6 service-detail">
                    <h2 className="service-detail-h m-0">
                      Lorium Ipsum <span>Dummy Text</span>{" "}
                    </h2>
                    <p className="mt-3">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                    <p>
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                      Lorem ipsum, or lipsum as it is sometimes known, is dummy
                      text used in laying out print, graphic or web designs.
                    </p>
                    <p className="mt-4">
                      <button type="button" className="btn cus-btn">
                        Read More
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row d-flex align-items-center mt-4">
                  <div className="col-lg-6 col-md-6 ">
                    <img src={about} alt="about" className="img-fluid" />
                  </div>
                  <div className="col-lg-6 col-md-6 service-detail">
                    <h2 className="service-detail-h m-0">
                      Lorium Ipsum <span>Dummy Text</span>
                    </h2>
                    <p className="mt-3">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                    <p>
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                      Lorem ipsum, or lipsum as it is sometimes known, is dummy
                      text used in laying out print, graphic or web designs.
                    </p>
                    <p className="mt-4">
                      <button type="button" className="btn cus-btn">
                        Read More
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnlineService;
