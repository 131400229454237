
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import login from "../../asset/img/login.png";
import Header from "../../component/header.js";
import Footer from "../../component/footer.js";
import { useDispatch } from "react-redux";
import { isToaster, userData } from "../../actions/index.js";
import axios from "axios";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [isLoginErr, setLoginErr] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    const savedPssword = localStorage.getItem("rememberedpass");

    
    if (savedEmail && savedPssword) {
      setUser((prevState) => ({ ...prevState, email: savedEmail }));
      setUser((prevState) => ({ ...prevState, password: savedPssword }));

      setRememberMe(true);
    }
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const showPassword = (id) => {
    const input = document.getElementById(id);
    input.type = input.type === "password" ? "text" : "password";
    setTimeout(() => {
      input.type = "password";
    }, 800);
  };

  const onLogin = () => {
    if (!user.email) {
      return dispatch(isToaster({ flag: true, msg: "Email is required *" }));
    }
    if (!user.password) {
      return dispatch(isToaster({ flag: true, msg: "Password is required *" }));
    }

    setLoginErr(false);
    setLoading(true);

    const postData = {
      email: user.email,
      password: user.password,
    };

    axios.post(`${process.env.REACT_APP_API_URL}/api/login`, postData)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setLoginErr(false);
          dispatch(userData(res.data));
          localStorage.setItem("userRole", res.data.user.role);
          localStorage.setItem("userToken", res.data.token);

          if (rememberMe) {
            localStorage.setItem("rememberedEmail", user.email);
            localStorage.setItem("rememberedpass", user.password);

          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedpass");

          }

          switch (res.data.user.role) {
            case "Advertiser":
              navigate("/advertiser");
              break;
            case "Super_Admin":
            case "Manager":
              navigate("/admin");
              break;
            case "Support":
              navigate("/support");
              break;
            default:
              console.error("Unexpected role: ", res.data.user.role);
              setLoginErr(true);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoginErr(true);
        console.error("Login failed: ", err);
      });
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="form-layout">
                <div className="row p-3 d-flex align-items-center">
                  <div className="col-lg-6">
                    <img src={login} alt="sign in" className="img-fluid" />
                  </div>
                  <div className="col-lg-6">
                    <div style={{ color: "#c01f25", fontSize: "14px" }} className={`${isLoginErr ? "visible" : "invisible"} text-center -mb-3`}>
                      The email address you entered isn't in our database!
                    </div>
                    <form autoComplete="off" id="multistep-form" onSubmit={(e) => { e.preventDefault(); }}>
                      <div className="singinform p-lg-4 d-flex justify-content-space-between h-fit flex-column">
                        <h4>SIGN INTO <span>YOUR ACCOUNT</span></h4>
                        <div className="inputField inputFieldtop">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={user.email}
                            onChange={handleInputs}
                            placeholder=" "
                            className="form-control form-control-ctm my-2"
                          />
                          <span>Email</span>
                        </div>
                        <div className="inputField inputFieldtop">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            value={user.password}
                            onChange={handleInputs}
                            className="form-control form-control-ctm my-2"
                            placeholder=" "
                          />
                          <span>Password</span>
                          <div className="pwdeyelogin" onClick={() => showPassword("password")}>
                            <i className="fa fa-eye"></i>
                          </div>
                        </div>
                        <p className="sendmsg mt-2">
                          <span className="float-start d-flex justify-content-space-between">
                            <input
                              className="form-check-input ml-0"
                              type="checkbox"
                              id="defaultCheck1"
                              checked={rememberMe}
                              onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <label className="form-check-label ml-4" style={{ marginLeft: "10px" }} htmlFor="defaultCheck1">
                              Remember me
                            </label>
                          </span>
                          <span className="float-end">
                            <Link to="/forgotPassword">Forgot Password</Link>
                          </span>
                        </p>
                        <div>
                          <button
                            type="submit"
                            disabled={loading}
                            onClick={onLogin}
                            className="btn sub-btn w-100"
                          >
                            Sign In &nbsp;
                            {loading ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : null}
                          </button>
                        </div>
                        <p className="mt-2">
                          Don't have an account?
                          <Link to="/signup" className="login-register">Register Here</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Login;
