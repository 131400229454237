import React from "react";

const FaqsAccordian = () => {
  return (
    <section className="spacing-page">
      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-9 col-sm-12 mx-auto">
            <div className="accordion" id="faqs">
              {/* Accordion Item 1 */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    style={{borderRadius:'0px'}}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <p className="m-0">Lorem ipsum is placeholder text</p>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#faqs"
                >
                  <div className="accordion-body faqsbody">
                    <p className="m-0">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups.
                    </p>
                  </div>
                </div>
              </div>

              {/* Accordion Item 2 */}
              <div className="accordion-item mt-3">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <p className="m-0">Lorem ipsum is placeholder text</p>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#faqs"
                >
                  <div className="accordion-body faqsbody">
                    <p className="m-0">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups.
                    </p>
                  </div>
                </div>
              </div>

              {/* Accordion Item 3 */}
              <div className="accordion-item mt-3">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <p className="m-0">Lorem ipsum is placeholder text</p>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#faqs"
                >
                  <div className="accordion-body faqsbody">
                    <p className="m-0">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups.
                    </p>
                  </div>
                </div>
              </div>

              {/* Accordion Item 4 */}
              <div className="accordion-item mt-3">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <p className="m-0">Lorem ipsum is placeholder text</p>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#faqs"
                >
                  <div className="accordion-body faqsbody">
                    <p className="m-0">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups.
                    </p>
                  </div>
                </div>
              </div>

              {/* Accordion Item 5 */}
              <div className="accordion-item mt-3">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <p className="m-0">Lorem ipsum is placeholder text</p>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#faqs"
                >
                  <div className="accordion-body faqsbody">
                    <p className="m-0">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqsAccordian;
