import React, { useEffect } from "react";
import "../asset/css/popup.css"; // Import your custom CSS for styling
import { useDispatch, useSelector } from "react-redux";
import { isToaster } from "../actions";
 
const Toaster = () => {
  const dispatch = useDispatch();
  const visible = useSelector((state) => state.is_toaster.flag);
  const message = useSelector((state) => state.is_toaster.msg);
 
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        dispatch(isToaster({ flag: false, msg: ''}));
      }, 4000); // 3 seconds
 
      return () => clearTimeout(timer);
    }
  }, [visible, dispatch]);
 
  return (
    <div className={`notification ${visible ? "show" : "hide"}`} >
      <div className="message" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>{message}</div>
    </div>
  );
};
 
export default Toaster;