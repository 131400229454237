import React from "react";
import advertiser from "../../asset/img/advertising.png";
import ads from "../../asset/img/ads.png";
import countries from "../../asset/img/countries.png";
import customer from "../../asset/img/customer.png";

const ServiceTime = () => {
  return (
    <>
      <section className="spacing-page about-service">
        <span className="about-service1"> </span>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-6">
              <div className="service-sec">
                <div className="m-1">
                  <img src={advertiser} alt="advertiser" />
                </div>
                <h5 className="m-0">1200+</h5>
                <p>Advertiser</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6">
              <div className="service-sec">
                <div className="m-1">
                  <img src={ads} alt="advertiser" />
                </div>
                <h5 className="m-0">2000+</h5>
                <p>Ads</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6">
              <div className="service-sec">
                <div className="m-1">
                  <img src={countries} alt="advertiser" />
                </div>
                <h5 className="m-0">50+</h5>
                <p>Countries</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6">
              <div className="service-sec">
                <div className="m-1">
                  <img src={customer} alt="advertiser" />
                </div>
                <h5 className="m-0">500+</h5>
                <p>Happy Customers</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceTime;
