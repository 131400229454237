import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isToaster } from "../../actions";
import logo from "../../asset/img/logo.png";
import { useDispatch } from "react-redux";

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const signupDetails = localStorage.getItem("accountToCreate");
  // Extract query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const success = searchParams.get("success");
  const trackId = searchParams.get("trackId");
  const status = searchParams.get("status");


  const onCreateAccount = () => {
    const postData = JSON.parse(signupDetails);
    postData.payment_received = 1;
    postData.lastname = "xyz";
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/register`,
        postData,
        customConfig
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/login");
          dispatch(
            isToaster({
              flag: true,
              msg: `Hi ${postData.firstname}, Your account is created Successfully!`,
            })
          );
          localStorage.removeItem("accountToCreate");
        }
      })
      .catch((err) => {
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
      });
  };

  

  const checkPayments = async () => {
    console.log(success, trackId, status);
    const userToken = localStorage.getItem("userToken");
    console.log(userToken);
  
    try {
      const response = await fetch(`https://socialmedia.quickvisit.me/api/get/trackbyid/${trackId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add this line if you need to pass the userToken for auth
          // 'Authorization': `Bearer ${userToken}`
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
  
      if (responseData?.status === true) { 
        if (!userToken) {
          dispatch(isToaster({ flag: true, msg: "Payment is verified" }));
          localStorage.setItem("age18",true)
          navigate(`/signup?step=4&trackId=${trackId}&success=${success}`);
        } else {
          dispatch(isToaster({ flag: true, msg: "Plan updated successfully" }));
          localStorage.setItem("age18",true)
          navigate('/advertiser/plan');
        }
      } else {
        navigate("/payment");
  
        let updatePlan = localStorage.getItem("accountToCreate");
        console.log(updatePlan);
  
        if (updatePlan) {
          updatePlan = JSON.parse(updatePlan); 
          updatePlan.plan = ""; 
          localStorage.setItem('accountToCreate', JSON.stringify(updatePlan)); 
        }
  
        dispatch(
          isToaster({
            flag: true,
            msg: "Payment is not verified, please try again!",
          })
        );
      }
    } catch (error) {
      console.error("Error occurred:", error);
      dispatch(isToaster({ flag: true, msg: "An error occurred. Please try again later." }));
    }
  };
  
  useEffect(() => {
    if (success == 1) {
      checkPayments();
    }
  }, []);
  return (
    <div className="payment-verification-container">
      <div class="payment-loader"></div>
      <img src={logo} alt="logo" />
      <p>Payment verification is in progress</p>
    </div>
  );
};

export default PaymentDetails;
