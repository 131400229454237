/** @format */
 
import axios from "axios"
import React, { useEffect, useState } from "react"
import no_data from "../../asset/img/no_data.png";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import DeleteEntry from "../component/DeleteEntry"
import {
  adData,
  advertiserData,
  idToDelete,
  isLoader,
  videoIdToDelete,
  viewAdData,
} from "../../actions"
import Plan from "../../asset/img/crown.png"
import play from "../../asset/img/play.png"
import profile_logo from "../../asset/img/profile.png"
import DeleteImage from "../component/DeleteImage"
import DeleteVideo from "../component/DeleteVideo"
 
const ViewAdvertiser = () => {
  const token = localStorage.getItem("userToken")
  const [data, setData] = useState()
  const [search, setSearch] = useState('')
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-advertisements-by-admin/${localStorage.getItem(
    "viewAdvertiser"
  )}?searchText=${search}&page=`
  const role = localStorage.getItem("userRole")
  const [deleteType, setDeleteType] = useState("")
  let navigate = useNavigate()
  let dispatch = useDispatch()
  let id = localStorage.getItem("viewAdvertiser")
  const advertiser = useSelector((s) => s.advertiser_data)
  // const [advertiser, setAdvertiser] = useState({
  //   firstname: "",
  //   lastname: "",
  //   hair_coloe: "",
  //   gender: "",
  //   body_type: "",
  //   age: "",
  //   ethnicity: "",
  //   eye_color: "",
  //   breast_size: "",
  //   height: "",
  //   images: [],
  //   videos: [],
  // })
  const onViewAd = (a) => {
    localStorage.setItem("viewAdId", a.id)
    dispatch(viewAdData(a))
  }
 
  const onVideoDelete = (id) => {
    dispatch(isLoader(true))
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/delete-video-by-id/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch(isLoader(false))
        // setAdvertiser(res.data.user)
        console.log(res)
      })
      .catch((err) => {
        dispatch(isLoader(false))
        console.log(err)
      })
  }
 
  // const onImageDelete = (id) => {
  //   dispatch(isLoader(true))
  //   axios
  //     .delete(
  //       `${process.env.REACT_APP_API_URL}/api/delete-image-by-id/${id}`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     )
  //     .then((res) => {
  //       dispatch(isLoader(false))
  //       setAdvertiser(res.data.user)
  //       console.log(res)
  //     })
  //     .catch((err) => {
  //       dispatch(isLoader(false))
  //       console.log(err)
  //     })
  // }
 
  const ads = useSelector((s) => s.ad_data)
 
  useEffect(() => {
    console.log("id: ", id)
    dispatch(isLoader(true))
    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/get-advertiser-by-admin/${localStorage.getItem(
            "viewAdvertiser"
          )}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isLoader(false))
          dispatch(advertiserData(res.data.user))
          // setAdvertiser(res.data.user)
          console.log("advertiser data at advertiser panel: ", res.data.user)
        })
        .catch((err) => {
          dispatch(isLoader(false))
        })
    } else {
      navigate("/login")
    }
 
    dispatch(isLoader(true))
    if (token) {
      axios
        .get(
          `${BASE_URL}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isLoader(false))
          setData(
            res.data.allAds
          )
          dispatch(adData(res.data.allAds.data));
          console.log("ads data at admin panel: ", res.data.allAds)
        })
        .catch((err) => {
          dispatch(isLoader(false))
        })
    } else {
      navigate("/login")
    }
  }, [])
 
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
 
  const onSearch = () => {
    dispatch(isLoader(true));
 
    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        dispatch(adData(res.data.allAds.data));
        console.log("Get all Admins : ", res.data.allAds);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };
 
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }
 
  const updateData = (url) => {
    dispatch(isLoader(true));
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        dispatch(adData(res.data.allAds.data));
        console.log("Get all Admins : ", res.data.users);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };
console.log(advertiser,"advertiser")
  return (
    <>
      <DeleteImage
        className={`${deleteType === "image" ? "d-block" : "d-none"}`}
      />
      <DeleteVideo
        className={`${deleteType === "video" ? "d-block" : "d-none"}`}
      />
      <DeleteEntry type="Advertiser"/>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <span className="d-flex align-items-center">
                  <span className="user_profile">
                    <img
                      src={
                        advertiser.profile_photo
                          ? advertiser.profile_photo
                          : profile_logo
                      }
                      style={{objectFit: 'cover'}}
                      alt="user"
                    />
                  </span>
                  <span className="ms-4">
                    <h4> {advertiser ? advertiser.username : "ABC"}</h4>
                  </span>
                </span>
                <span>
                  <div className="plan-a">
                    <div className="d-flex align-items-center ">
                      <img src={Plan} alt="plan-a" className="img-fluid" />
                      <span className="mx-4 plan-a-text">
                        <p className="m-0">
                          {advertiser.username} active package is -{" "}
                          <span className="plan-a-detail">{advertiser.plan}</span> <br />
                          Expire:{" "}
                          <span className="plan-a-detail">{advertiser?.expired_at ? advertiser?.expired_at:"--/--/----"}</span>
                        </p>
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
 
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="viewcard ">
                <div className="row basic-detail">
                  <h4 className="infoedit m-0"> Basic Info </h4>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Name</p>
                        <h6 className="basicdata">
                          {advertiser
                            ? advertiser.firstname + " " + advertiser.lastname
                            : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Hair Color</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.hair_color : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Gender</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.gender : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Body Type</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.body_type : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Age</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.age : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Ethnicity</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.ethnicity : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Eye Color</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.eye_color : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Breast Size</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.breast_size : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Height</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.height : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo"> </p>
                        <h6 className="basicdata"> </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
 
          <div className="row mt-4">
            <div className="col-lg-12">
              <h6>Photos</h6>
            </div>
          </div>
          <div className="row viewcardtop1 viewpro-img">
            {advertiser
              ? advertiser.images.map((x) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-6">
                      <div>
                        <div className="view-profile-img position-relative">
                          <img
                            src={x.image}
                            alt="advertiser"
                            className="img-fluid"
                            style={{objectFit: 'cover'}}
                          />
                          {/* <i
                            className="fa-solid fa-xmark pointer"
                            onClick={() => onImageDelete(x.id)}
                          ></i> */}
                          <p
                            type="button"
                            className="my-0 mx-2 delete-img-admin"
                            onClick={() =>
                              dispatch(idToDelete(x.id)) &
                              setDeleteType("image")
                            }
                          >
                            <i className="fa-solid fa-xmark pointer"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
 
            {/* <div className="col-lg-3 col-md-3 col-xs-6">
              <div>
                <div className="img_v_center-view">
                  <img
                    src={advertiserPic}
                    alt="advertiser"
                    className="img-fluid"
                  />
                  <i className="fa-solid fa-xmark pointer"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-xs-6">
              <div>
                <div className="img_v_center-view">
                  <img
                    src={advertiserPic}
                    alt="advertiser"
                    className="img-fluid"
                  />
                  <i className="fa-solid fa-xmark pointer"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-xs-6">
              <div>
                <div className="img_v_center-view">
                  <img
                    src={advertiserPic}
                    alt="advertiser"
                    className="img-fluid"
                  />
                  <i className="fa-solid fa-xmark pointer"></i>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="row mt-3">
            <div className="col-lg-12">
              <span className="float-end">
                <button type="button" className="btn pri-btn">
                  Load More Photos
                </button>
              </span>
            </div>
          </div> */}
 
          <div className="row mt-4">
            <div className="col-lg-12">
              <h6>Videos</h6>
            </div>
          </div>
 
          <div className="row ">
            {advertiser
              ? advertiser.videos.map((x) => {
                  return (
                    <div className="col-lg-6 col-md-6">
                      <div className="video-container">
                        <div className="img_v_center-view">
                          <video id="videoElement" className="w-100" loop muted controls>
                            <source
                              src={x.video}
                              loop
                              className="hover-to-play w-100"
                            />
                          </video>
                          {/* <span id="customPlayButton" className="play-button">
                            <img src={play} alt="play/pause" />
                          </span> */}
                          <i
                              className="fa-solid fa-xmark pointer"
                            style={{ color: "#c01f25" }}
                            onClick={() =>
                              dispatch(videoIdToDelete(x.id)) &
                              setDeleteType("video")
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
          {/* <div className="row mt-3">
            <div className="col-lg-12">
              <span className="float-end">
                <button type="button" className="btn pri-btn">
                  Load More Videos
                </button>
              </span>
            </div>
          </div> */}
 
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between ">
                <span>
                  <h4>{advertiser.firstname? advertiser.firstname : 'All'} Ads</h4>
                </span>
                <span className="position-relative">
                  <input
                    type="text"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    className="form-control form-control-ctm "
                  />
                  <i className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass pointer" onClick={onSearch}></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-lg-12 mt-3">
              <div className="dashboardtable">
                <div className="table-scrollable">
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Ads Name</th>
                        <th>Profile Name</th>
                        <th>Publish Date</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        {/* <th>Active</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(ads) && ads.length != 0
                        ? ads.map((x) => {
                            return (
                              <tr>
                                <td>{x.ad_name}</td>
                                <td>
                                  {advertiser
                                    ? advertiser.firstname +
                                      " " +
                                      advertiser.lastname
                                    : null}
                                </td>
                                <td>{x.publish_date}</td>
                                <td>{x.location}</td>
                                <td>{x.state}</td>
                                <td>{x.country}</td>
                                {/* <td>
                                  {(x.expired === "1") & (x.paused === 0)
                                    ? "EXPIRED"
                                    : null}{" "}
                                  {(x.paused === 1) & (x.expired === "0")
                                    ? "PAUSED"
                                    : null}{" "}
                                  {(x.expired === "1") & (x.paused === 1)
                                    ? "BOTH"
                                    : null}{" "}
                                  {(x.expired === "0") & (x.paused === 0)
                                    ? "ACTIVE"
                                    : null}
                                </td> */}
                                <td className="table-action">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span>
                                      {
                                          role === "Super_Admin" ||
                                            role === "Manager" ?  <Link
                                            to="/admin/viewAds"
                                            onClick={() => onViewAd(x)}
                                          >
                                            <i className="fa-regular fa-eye"></i>
                                          </Link> : <Link
                                        to="/support/viewAds"
                                        onClick={() => onViewAd(x)}
                                      >
                                        <i className="fa-regular fa-eye"></i>
                                      </Link>

                                      }
                                     
                                    </span>
                                    {/* &nbsp; &nbsp; &nbsp;
                                    <span>
                                      <Link to="#">
                                        <i className="far fa-trash-alt pointer" ></i>
                                      </Link>
                                    </span> */}
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        : (
                          <tr>
                            <td colSpan={7}>
                              <img
                                src={no_data}
                                alt="Description of the image"
                                width={277}
                              />
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
 
               
                {/* pagination code */}
 
                {data ? (
                  data.last_page > 1 ? (
                    <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                      <div
                        className="pagination-css-pre"
                        onClick={() => updateData(data.prev_page_url)}
                        disabled={data.current_page === 1}
                      >
                        <span aria-hidden="true">
                          <i className="fa fa-chevron-left" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className={`pagination-css-active`}>
                          {data.current_page <= data.last_page
                            ? data.current_page
                            : data.last_page}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 1
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 1}`)
                          }
                        >
                          {data.current_page + 1}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 2
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 2}`)
                          }
                        >
                          {data.current_page + 2}
                        </span>
                        <span className="pagination-css ">{` ... `}</span>
                        <button
                          className="pagination-css "
                          disabled={data.current_page === data.last_page}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.last_page}`)
                          }
                        >{` ${data.last_page}`}</button>
                      </div>
                      <div
                        className="pagination-css-next"
                        onClick={() => updateData(data.next_page_url)}
                        disabled={data.current_page === data.last_page}
                      >
                        <span aria-hidden="true">
                          <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  ) : null
                ) : null}
 
                {/* pagination code */}
 
              </div>
            </div>
          </div>
 
          <div className="row mt-4">
            <div className="col-lg-12 d-flex justify-content-end">
              <button type="button" className="btn pri-btn messagebtn">
                Send Message
              </button>{" "}
              &nbsp; &nbsp; &nbsp;
              <button type="button" onClick={() => dispatch(idToDelete(advertiser.id))} className={`btn pri-btn messagebtn ${role === 'Support' ? 'd-none' : 'd-block'}`}>
                Delete Profile
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
 
export default ViewAdvertiser