import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  advertisementId,
  advertiserCity,
  advertiserId,
  isLoader,
  showAvailability,
  topListAction,
} from "../../actions";
import { useNavigate } from 'react-router-dom';
import handleLastActive from '../../component/handleLastActive';
import BookingButton from '../SingleProfile/BookingButton';
import Calendar from '../../advertiser/component/Calendar';

const HeroCarousel = () => {
  const dispatch = useDispatch();
  const City = localStorage.getItem("advertiserCity");
  const topList = useSelector((s) => s.top_list);
  const [latitude, setLatitude] = useState(localStorage.getItem("latitude") || "");
  const [longitude, setLongitude] = useState(localStorage.getItem("longitude") || "");
  let navigate = useNavigate();
  const available_days = useSelector((s) => s.available_days);
  const [showCalendar, setShowCalendar] = useState([]);

  const fetchAdvertisements = async (city, lat, lon) => {
    dispatch(isLoader(true));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-top-primary-advertisements?city=${encodeURIComponent(city)}&latitude=${lat}&longitude=${lon}`
      );
      dispatch(topListAction(response?.data?.topPrimaryAdvertisements?.data));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(isLoader(false));
    }
  };

  const findMyLocation = () => {
    const success = (position) => {
      console.log(position)
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;
      setLatitude(lat);
      setLongitude(lon);
      fetchGeoData(lat, lon);
    };

    const error = () => {
      console.log("Geolocation blocked, fetching data from IP Address");
      fetchCityFromIP();
    };

    navigator.geolocation.getCurrentPosition(success, error);
  };

  const fetchGeoData = async (lat, lon) => {
    const geoApiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`;
    
    try {
      const response = await axios.get(geoApiUrl);
      console.log(response)
      const city = response.data.city;
      localStorage.setItem("advertiserCity", city);
      fetchAdvertisements(city, lat, lon);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCityFromIP = async () => {
    try {
      const res = await axios.get("https://ipinfo.io");
      const city = res.data.city.replace("ā", "a");
      localStorage.setItem("advertiserCity", city);
      fetchAdvertisements(city, latitude, longitude);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!City) {
      findMyLocation();
    } else {
      fetchAdvertisements(City, latitude, longitude);
    }

    // Update localStorage whenever latitude or longitude changes
    if (latitude) {
      localStorage.setItem("latitude", latitude);
    }
    if (longitude) {
      localStorage.setItem("longitude", longitude);
    }
  }, [City, latitude, longitude]);

  const onCheckAvailability = (dates) => {
    dispatch(showAvailability(true));
    setShowCalendar(dates);
  };

  console.log(City, latitude, longitude);
  console.log(topList, "data");

  return (
    <>
      <section className="mt-4">
        <div id="bannerslider" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators premium-carousel-dot">
            {topList.map((_, i) => (
              <li
                key={i}
                data-bs-target="#bannerslider"
                data-bs-slide-to={i}
                className={`${i === 0 ? "active" : ""}`}
              />
            ))}
          </ol>
          <div className="carousel-inner">
            {topList.map((x, i) => (
              <div key={x.id} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="header_hero">
                        <img className="img-fluid" src={x.profile_photo} alt="slide" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="live-ad-card">
                        <h2>{x.fullname}</h2>
                        <div className="detalist-ad mt-3">
                          <p>
                            <span className="details1">Gender: </span>
                            {x.gender}
                          </p>
                          <p>
                            <span className="details2">Age: </span>
                            {x.age}
                          </p>
                        </div>
                        <div className="detalist-ad flex-row flex-lg-row">
                          <p>
                            <span className="details1">City: </span>
                            {x.location}
                          </p>
                          <p>
                            {handleLastActive(
                              x.last_active ? x.last_active : "2024-04-22"
                            )}
                          </p>
                        </div>
                        <div className="mt-3">
                          <div className="detalist-ad">
                            <span className="details1">Ad Name:</span>
                            {x.ad_name}
                          </div>
                          <div className="detalist-ad">
                            <span className="details1">Height:</span>
                            {x.height}
                          </div>
                          <div className={`detalist-ad ${x.gender === "Male" ? "d-none" : "d-flex"}`}>
                            <span className="details1">Breast Size:</span>
                            {x.breast_size}
                          </div>
                          <div className="detalist-ad">
                            <span className="details1">Ethnicity:</span>
                            {x.ethnicity}
                          </div>
                          <div className="detalist-ad">
                            <span className="details1">Body Type:</span>
                            {x.body_type}
                          </div>
                        </div>
                        <div className="text-center mt-2">
                          <span className="detalist-ad-ava">
                            <button
                              type="button"
                              className="btn pri1-btn detalist-ad-ava-btn"
                              onClick={() => {
                                onCheckAvailability();
                                dispatch(advertiserId(x.advertiser_id));
                                dispatch(advertisementId(x.ad_id));
                              }}
                            >
                              Check Availability
                            </button>
                          </span>
                        </div>
                        <div className="mt-4 text-center d-flex flex-col justify-content-center">
                          <div className="d-flex justify-content-center hero-btn-crausal">
                            <div>
                              <BookingButton
                                type="hero"
                                advertiser_id={x.advertiser_id}
                              />
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn cus-btn"
                                onClick={() => {
                                  navigate("/view-profile");
                                  dispatch(advertiserId(x.advertiser_id));
                                  dispatch(advertisementId(x.ad_id));
                                }}
                              >
                                More Detail
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a
            className="carousel-control-prev"
            href="#bannerslider"
            role="button"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#bannerslider"
            role="button"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </div>
      </section>
      <Calendar
        availableDates={
          available_days
            ? available_days
            : ["2024-03-05", "2024-03-18", "2024-03-19", "2024-03-20"]
        }
      />
    </>
  );
};

export default HeroCarousel;
