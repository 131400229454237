import React from "react";
import { Link } from "react-router-dom"; 
import ViewAvaibilityTable from "./ViewAvaibilityTable";
import ViewLocationCard from "./ViewLocationCard";
import ViewBasicInfoCard from "./ViewBasicInfoCard";
import ViewPriceCard from "./ViewPriceCard";
import AboutProfileCard from "./AboutProfileCard";
import ViewImagesCard from "./ViewImagesCard";
import ViewProfileInfoCard from "./ViewProfileInfoCard";
import ViewVideoCard from "./ViewVideoCard";
import BookingButton from "./BookingButton";
import SimilarAdvertisements from "../HomeComponent/SimilarAdvertisements";
const ViewProfileNav = () => {
  return (
    <>
      <section className="view-profile-nav">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active profile-nav"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-bs-controls="pills-home"
                    aria-selected="true"
                  >
                    <i className="fa fa-circle-info"></i> Info
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link profile-nav"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-bs-controls="pills-profile"
                    aria-selected="false"
                  >
                    <i className="fa fa-earth-americas"></i> Overview
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link profile-nav"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-bs-controls="pills-contact"
                    aria-selected="false"
                  >
                    <i className="fa fa-photo-film"></i> Media
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      data-bs-toggle="tab"
                     role="presentation"
                     data-bs-target="#home-tab-pane"
                      aria-labelledby="pills-home-tab"
                      tabIndex="0"
                    >
                      <div className="row">
                        <div className="col-lg-7 col-md-7">
                           <ViewProfileInfoCard />
                         <ViewLocationCard />
                        </div>
                        <div className="col-lg-5 col-md-5">
                         <BookingButton />
                          <AboutProfileCard />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                      tabIndex="0"
                    >
                      <div className="row">
                        <div className="col-lg-7 col-md-7">
                        <ViewBasicInfoCard />
                         <ViewLocationCard />
                          <div className="mt-3">
                            <div className="profile-info-nav">
                              <div className="row position-relative profile-info-tab">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="m-0">
                                    {" "}
                                    Availability{" "}
                                    <span className="profile-info-tab-txt">
                                      Info
                                    </span>
                                  </h4>
                                </div>
                                <span className="profile-info-border"></span>
                              </div>
                              <div className="row mt-2">
                                <div className="col-lg-12">
                                <ViewAvaibilityTable />
                                </div>
                              </div>
                              {/* <div className="row mt-2">
                                <div className="col-lg-12">
                                  <div className="d-flex">
                                    <span className="availble-yes-txt">
                                      <div className="availble-yes">                                        
                                      </div>
                                      <p>Availability</p>
                                    </span>
                                    <span className="availble-yes-txt">
                                      <div className="availble-No">                                        
                                      </div>
                                      <p>Non-Availability</p>
                                    </span>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                        <BookingButton/>
                         <AboutProfileCard />
                      <ViewPriceCard />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                      tabIndex="0"
                      style={{ color: "red" }}
                    >
                       <div className="row">
                        <div className="col-lg-7 col-md-7">
                      <ViewImagesCard />
                     <ViewVideoCard />
                         
                        </div>
                        <div className="col-lg-5 col-md-5">
                        <BookingButton />
                         <AboutProfileCard />
                      <ViewPriceCard />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <SimilarAdvertisements ID={localStorage.getItem("viewAdId")} advertiserCity={data.city} eye_color={data.eye_color} hair_color={data.hair_color} breast_size={data.breast_size} height={data.height}/> */}
    </>
  );
};

export default ViewProfileNav;
