import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import no_data from "../../../asset/img/no_data.png";
import { idToDelete, isLoader, isToaster } from "../../../actions";
import DeleteEntry from "../../component/DeleteEntry"
import { couponsList } from "../../../actions";

const Coupon = () => {
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const coupons_list = useSelector((s) => s.coupons_list)
  const role = localStorage.getItem("userRole");
  // const [Coupons, setCoupons] = useState([]);
  const fetchCoupons = () => {
    dispatch(isLoader(true));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-coupons`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        // navigate("/admin/Coupon")
        dispatch(couponsList(res.data.coupons))
        // setCoupons(res.data.coupons);
        // dispatch(
        //   isToaster({ flag: true, msg: "Coupon created successfully!" })
        // );
      })
      .catch((err) => {
        dispatch(isLoader(false));
        // dispatch(isToaster({ flag: true, msg: "Oops! Something went wrong" }));
      });
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  return (
    <>
    
    <DeleteEntry type="Coupon" />
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex justify-content-between align-items-center">
                  <h4 className="m-0">Coupons</h4>
                </span>
                <span>
                  <div>
                  {role === "Super_Admin" || role === "Manager" ? (
                     <Link to="/admin/createCoupon">
                     {" "}
                     <button type="button" className="btn cus-btn">
                       Create New Coupons
                     </button>
                   </Link>
                  ):( <Link to="/support/createCoupon">
                    {" "}
                    {role === "Super_Admin" || role === "Manager" ? (                    <button type="button" className="btn cus-btn">
                      Create New Coupons
                    </button>):null}

                  </Link>)}
                   
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="dashboardtable">
                <div
                  className={`table-scrollable  
                 
                      ? "border-radius-n-pagination"
                      : "border-radius-w-pagination"
                  }`}
                >
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Coupon Name</th>
                        <th>Coupon Code</th>
                        <th>Discount Type</th>
                        <th>Amount</th>
                        {/* <th>Usage</th> */}
                        <th>Expiry Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(coupons_list) && coupons_list.length != 0
                        ? coupons_list.map((x) => {
                            return (
                              <tr>
                                <td>{x.coupon_name}</td>
                                <td>{x.coupon_code}</td>
                                <td>{x.discount_type}</td>
                                <td>{x.amount}</td>
                                {/* <td>10/50</td> */}
                                <td>{x.expiry_date.substring(0, 10)}</td>
                                <td className="table-action">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <p type="button" className="my-0 mx-2 " onClick={() => dispatch(idToDelete(x.id))}>
                                      <i className="far fa-trash-alt"></i>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : (
                          <tr>
                            <td colSpan={6}>
                              <img
                                src={no_data}
                                alt="Description of the image"
                                width={277}
                              />
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Coupon;
