/** @format */
 
import React, { useState } from "react"
import { Link } from "react-router-dom"
import forgotpwd from "../../asset/img/forgotpwd.png"
import Footer from "../../component/footer"
import axios from "axios"
import Header from "../../component/header"
import { useDispatch } from "react-redux"
import { isToaster } from "../../actions"
// import { useNavigate } from "react-router-dom"
 
const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  // let navigate = useNavigate()
const dispatch = useDispatch()
  const resetPassword = () => {
    if(!email){
      return dispatch(isToaster({ flag: true, msg: "Enter email" }));
 
    }
    let postData = {
      email: email,
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/forgot-password`,postData)
      .then((res) => {
        if (res?.data?.status) {
          dispatch(isToaster({ flag: true, msg: "Please check your mail id, We have sent you a link to reset your account password" }));
          //  alert("Please check your mail id, We have sent you a link to reset your account password")
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  let Value
  const handleInput = (e) => {
    Value = e.target.value
    setEmail(Value)
  }
 
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="form-layout">
                <div className="row p-3 d-flex align-items-center">
                  <div className="col-lg-6">
                    <img src={forgotpwd} alt="sing up" className="img-fluid" />
                  </div>
                  <div className="col-lg-6">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <div>
                        <div className="singinform p-lg-4">
                          <h4>
                            FORGOT <span>PASSWORD</span>
                          </h4>
                         
                          <div className="inputField1 inputField1top input-top-sign">
                            <input
                              type="text"
                            id="email"
                            name="email"
                            onChange={handleInput}
                            value={email}
                              className="form-control form-control-ctm my-3"
                              placeholder=""
                            />
                            <span>Enter Email Address</span>
                          </div>
                              <button
                                type="submit"
                                className="btn sub-btn w-100 mt-4"
                                onClick={resetPassword}
                              >
                               Request Link
                              </button>
                              <p className="mt-2 text-center">
                            Back to
                             <Link to="/login" className="login-register">Login</Link>
                          </p>
                          </div>
                         
                        </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
 
export default ForgotPassword