/** @format */

import React, { useEffect, useState } from "react"
import DatePicker, { Calendar } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { useDispatch } from "react-redux"
import axios from "axios"
import { useSelector } from "react-redux"
import { isLoader, isToaster, showEditableCalendar } from "../../actions"
import { availabilityData } from "../../actions"
import UpdateAvailability from "./UpdateAvailability"

const EditAvailablity = (props) => {
  let token = localStorage.getItem("userToken")
  let show = useSelector((s) => s.show_editable_calendar)
  let data = useSelector((s) => s.editable_calendar_data)
  const [dates, setDates] = useState(data.dates)
  let dispatch = useDispatch()
  const onUpdate = async () => {
    dispatch(isLoader(true))
    let obj
    let postDate = []
    dates.map((x) => {
      obj = `${x.year}-${String(x.month.number).padStart(2, "0")}-${String(
        x.day
      ).padStart(2, "0")}`
      postDate.push(obj)
    })
    console.log("dates after edit: ", dates , data.id)
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    
    let postdata = {
      dates: postDate
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/update-availability/${data.id}`,
        postdata,
        customConfig1
      )
      .then((res) => {
        dispatch(isToaster({flag: true, msg: 'Availability updated Successfully!'}))
        dispatch(isLoader(false))
        dispatch(isLoader(true))
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/get-availabilities`,
            customConfig1
          )
          .then((Res) => {
            dispatch(isLoader(false))
            dispatch(availabilityData(Res.data.availabilities.data))
            dispatch(showEditableCalendar(false))
          })
          .catch((err) => {
            console.log(err)
          })
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    console.log("data in Edit Calendar", data)
  }, [])
  return (
    <>
      <div className={`editable-calendar ${show ? "d-block" : "d-hidden"}`}>
        <div
          className="modal fade"
          id="editcalender"
          // data-backdrop="static"
          // data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="editcalender"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content price-table p-3">
              <div className="position-relative">
                <h2 className="modal-title fs-5 text-body" id="editcalenderLabel">
                  {" "}
                </h2>
                <span
                  className="position-absolute pointer cross_close top-0 end-0"
                  onClick={() => dispatch(showEditableCalendar(false))}
                >
                  <i
                    className="fa-solid fa-x"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></i>
                </span>
              </div>
              <div className="modal-body editavailablity_popup mx-auto">
                {/* <Calendar
                  value={data.dates}
                  minDate={new Date().toISOString().split("T")[0]}
                  onChange={setDates}
                  multiple
                  sort
                  calendarPosition="bottom-center"
                  plugins={[<DatePanel />]}
                /> */}
                {/* <UpdateAvailability/> */}
              </div>

              <div className="p-2">
                <div className="d-flex justify-content-center">
                  <button type="button" className="btn cus-btn " data-dismiss="modal"
                    aria-label="Close" onClick={() => {
                    onUpdate()
                  }}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditAvailablity
