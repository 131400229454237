/** @format */

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useNavigate } from "react-router-dom";
import profile_logo from "../../asset/img/icon/profile_logo.png"
import advertising from "../../asset/img/advertising.png"
import "../../asset/css/admin.css"
import Advertiser from "../component/Advertiser"
import LatestAdsdashboard from "../component/LatestAdsdashboard"
import LatestAds from "./Ads/LatestAds"
import axios from "axios"
import { isLoader } from "../../actions"
import LatestAdsTable from "../component/LatestAdsTable"

const AdminDashboard = () => {
  const token = localStorage.getItem("userToken")
  let dispatch = useDispatch()
  const data = useSelector((state) => state.user_data)
  const [total, setTotal] = useState({
    totalAdvertiser: null,
    activeAdvertisers: null,
    totalAds: null,
    totalRevenue: null,
  })
  // const navigate = useNavigate();
  // const logout = () => {
  //   localStorage.removeItem("userToken");
  //   navigate("/login");
  // };

  useEffect(() => {
    dispatch(isLoader(true))
    axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/get-dashboard-totals`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          dispatch(isLoader(false))
          setTotal(res.data)
          // console.log("total data: ", res)
        })
        .catch((err) => {
          dispatch(isLoader(false))
          // navigate("/login")
        })
  }, [])

  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <span className="user_profile">
                  <img
                    src={data.profile_photo ? data.profile_photo : profile_logo}
                    style={{objectFit: 'cover'}}
                    alt="user"
                  />
                </span>
                <span className="ms-4">
                  <h4>Welcome, {data.firstname}</h4>
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="admin-dash_card">
                <h5 className="text-center">TOTAL ADVERTISERS</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="admin-dash-card-body">
                    <img src={advertising} alt="Total Ads" />
                  </span>
                  <span>
                    <p className="m-0">{total.totalAdvertiser? total.totalAdvertiser : 0}</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="admin-dash_card">
                <h5 className="text-center">ACTIVE ADVERTISERS</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="admin-dash-card-body">
                    <img src={advertising} alt="Total Ads" />
                  </span>
                  <span>
                    <p className="m-0">{total.activeAdvertisers? total.activeAdvertisers : 0}</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="admin-dash_card">
                <h5 className="text-center">TOTAL ADS</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="admin-dash-card-body">
                    <img src={advertising} alt="Total Ads" />
                  </span>
                  <span>
                    <p className="m-0">{total.totalAds? total.totalAds : 0}</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ds-card">
              <div className="admin-dash_card">
                <h5 className="text-center">TOTAL REVENUE</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="admin-dash-card-body">
                    <img src={advertising} alt="Total Ads" />
                  </span>
                  <span>
                    <p className="m-0">{total.totalRevenue? total.totalRevenue: 0}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Advertiser />
          <div className="mt-5"> 
          <LatestAdsTable />
          </div>
        </div>
      </section>
      
    </>
  )
}

export default AdminDashboard
