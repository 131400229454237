import "../../asset/css/adminMyMessage.css";
import { useState, useEffect, useRef } from "react";
import { chatMessages } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { getSocket } from "../../config/socket.config";
import No_Chat_Request_img from "../../asset/img/No_Request.png";
import { isToaster } from "../../actions";
const MyMessageAdmin2 = () => {
  const dispatch = useDispatch();
  let token = localStorage.getItem("userToken");
  const chatStore = useSelector((s) => s.chat_messages);
  const data = useSelector((state) => state.user_data);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [toggleMenu, setToggleMenu] = useState(false);
  const sideMenuRef = useRef(null);
  const [roomId, setRoomId] = useState("");
  const [userMsg, setUserMsg] = useState([]);
  const [chatReqAccepted, setChatReqAccepted] = useState(false);
  const [chatRequests, setChatRequests] = useState([]);
  const [requestAlert, setRequestAlert] = useState(false);
  const [socket, setSocket] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [showImg, setShowImg] = useState(null);

  // console.log(data);

  useEffect(() => {
    if (roomId) {
      const handleBeforeUnload = (event) => {
        socket.emit("end-chat", { roomId: roomId, joinRoom: "Support-room" });

        setChatReqAccepted(false);
        // console.log(messages.filter((msg) => msg[roomId]));
        setMessages(messages.filter((msg) => !msg[roomId]));
        setMessages(
          messages.filter(
            (msg) => msg.rooid !== roomId && msg.rooid !== data.id
          )
        );
        // const message =
        //   "Are you sure you want to leave? Changes you made may not be saved.";
        // event.preventDefault();
        // event.returnValue = message;
        // return message;
      };

      // Add the event listener
      // window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleBeforeUnload);

      // Clean up the event listener when the component unmounts
      return () => {
        // window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleBeforeUnload);
      };
    }
  }, [roomId]);

  useEffect(() => {
    const socketInstance = getSocket();
    setSocket(socketInstance);

    socketInstance.connect();
    socketInstance.emit("joinRoom", {
      user: data.id,
      roomName: "Support-room",
    });

    // Listen for incoming messages
    socketInstance.on("message", (incomingMessage) => {
      setMessages((prevMessages) => [...prevMessages, incomingMessage]);
    });

    // Listen for chat requests
    socketInstance.on("chat-request", ({ chatRequests, message, roomid }) => {
      console.log(message)
      console.log(chatRequests)
      setMessages((prevMessages) => [...prevMessages, message]);
      setRequestAlert(true);
      setChatRequests(chatRequests);
    });

    // Listen for chat requests updates
    socketInstance.on("chat-requests-updated", (updatedChatRequests) => {
      // console.log(updatedChatRequests);
      // setChatRequests(updatedChatRequests);
    });

    // Listen for chat acceptance
    socketInstance.on("chat-accepted", ({ chatAccepted }) => {
      if (chatAccepted) {
        setRequestAlert(false);
      }
    });

    socketInstance.on("end-chat", ({ chatRequests, message, user }) => {
      // console.log("from support ", message, user);
      if (user) {
        // setRoomId("");
        // window.location.reload();
        //------------
        socketInstance.emit("end-chat", {
          roomId: roomId,
          joinRoom: "Support-room",
        });

        setChatReqAccepted(false);
        console.log(messages.filter((msg) => msg[roomId]));

        setMessages(messages.filter((msg) => !msg[roomId]));
        setMessages(
          messages.filter(
            (msg) => msg.rooid !== roomId && msg.rooid !== data.id
          )
        );
        setRoomId("");
        dispatch(
          isToaster({ flag: true, msg: "Chat has ended by Advertiser" })
        );
      }
      //previous code -----
      // setChatRequests(chatRequests);
      // setMessages(messages.filter((msg) => !msg[roomId]));
      // setMessages(
      //   messages.filter((msg) => msg.rooid !== roomId && msg.rooid !== data.id)
      // );
    });

    // Cleanup function
    return () => {
      socketInstance.off("message");
      socketInstance.off("chat-request");
      socketInstance.off("chat-requests-updated");
      socketInstance.off("chat-accepted");
      socketInstance.disconnect();
    };
  }, []);




  const handleSendMessage = async () => {
    // Ensure inputValue is not empty if roomId is available, or handle attachment alone
    if ((roomId && inputValue.trim()) || attachment) {
      const currentTime = new Date();
      let hr = currentTime.getHours();
      const min = currentTime.getMinutes().toString().padStart(2, "0");

      
      const ampm = hr >= 12 ? "PM" : "AM";

      
      hr = hr % 12;
      hr = hr ? hr : 12; 

      const timeFormatted = `${hr}:${min} ${ampm}`;

      let msg = {
        rooid: roomId,
        role: data.role,
        firstname: data.firstname,
        senderId: data.id,
        msg: inputValue,
        profile: data.profile_photo,
        time: timeFormatted,
        attachment: null,
      };

      if (attachment) {
        const formData = new FormData();
        formData.append("message", null);
        formData.append("sender_id", data.id);
        formData.append("upload_document", attachment);

        try {
          const response = await fetch(
            "https://socialmedia.quickvisit.me/api/reciever/message",
            {
              method: "POST",
              headers: { Authorization: `Bearer ${token}` },
              body: formData,
            }
          );

          if (response.ok) {
            const result = await response.json();
            console.log("File uploaded successfully:", result);

            // Update message object with the attachment URL
            msg.attachment = result.attachment;
          } else {
            console.error("Failed to upload file. Status:", response.status);
            // Keep the attachment as null if upload fails
          }
        } catch (error) {
          console.error("Error during file upload:", error);
          // Keep the attachment as null if an error occurs
        }
      }

      // Emit the message (whether or not an attachment was successfully uploaded)
      socket.emit("message", { roomid: roomId, message: msg });

      // Reset input and attachment state
      setInputValue("");
      setAttachment(null);
    }
  };

  const handleSendAttachment = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file);
      // const read = new FileReader();
      // read.onloadend = () => {
      //   setAttachment(read.result);
      // };
      // read.readAsDataURL(file);
    }
  };
  // console.log(attachment);
  const Toggle_Side_menu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleCloseToggle = (e) => {
    if (sideMenuRef.current && !sideMenuRef.current.contains(e.target)) {
      setToggleMenu(false);
    }
  };

  const acceptChatRequest = (roomid) => {
    // console.log("accepted button clicked ", roomid);
    if(chatReqAccepted===true){
      window.alert("Please complete the ongoing chat before starting a new one.")
    }else{
      setRoomId(roomid);
      if (roomid) {
        socket.emit("accept-chat-request", {
          acceptedById: data.id,
          roomid: roomid,
          leaveRoom: "Support-room",
        });
        const filteredMessages = messages.filter((msg) => msg[roomid]);
  
        if (filteredMessages.length === 0) {
          console.log("No messages found for the room.");
          return;
        }
        const latestMessage = filteredMessages[filteredMessages.length - 1];
        // console.log("Filtered messages: ", filteredMessages);
        // console.log("Latest message: ", latestMessage);
        if (latestMessage && latestMessage[roomid]) {
          setMessages((prevMessages) => [...prevMessages, latestMessage[roomid]]);
        }
        setChatReqAccepted(true);
        setChatRequests(chatRequests.filter((Req) => Req.roomid !== roomid));
      }
    }
  };

  const handleEndChat = () => {
    // console.log("end chat button clicked", roomId);
    if (roomId) {
      socket.emit("end-chat", { roomId: roomId, joinRoom: "Support-room" });
      setChatReqAccepted(false);
      setMessages(messages.filter((msg) => !msg[roomId]));
      setMessages(
        messages.filter((msg) => msg.rooid !== roomId && msg.rooid !== data.id)
      );
      setRoomId("");
    }
    // messages.map((msg)=>console.log(msg[roomId]))
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseToggle);
    return () => {
      document.removeEventListener("mousedown", handleCloseToggle);
    };
  }, []);

  useEffect(() => {
    // localStorage.setItem("chat", JSON.stringify(messages));
    const item = document.getElementById("main_chat_box");
    if (item) {
      item.scrollTop = item.scrollHeight;
    }
  }, [messages]);

  // console.log(messages)
  // console.log(chatRequests)
  const handleOpenImg = (e) => {
    console.log(e.target.src);
    setShowImg(e.target.src);
  };
  console.log(showImg);

  return (
    <>
      <div
        className="modal fade .bg-dark-subtle"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content .bg-dark-subtle"
            style={{ width: "fit-content", backgroundColor: "#393939" }}
          >
            <div className="modal-header  border-bottom border-danger">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img
                src={showImg}
                alt="Displayed image"
                className="img-fluid"
              />
            </div>
            <div className="modal-footer border-top border-danger">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-danger"
              onClick={()=>window.open(showImg)}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard myMessageAdmin container-fluid d-flex p-0 vh-100">
        <div
          ref={sideMenuRef}
          className={`side_menu z-3 d-flex flex-column flex-grow-1 position-relative  ${
            toggleMenu ? "toggle_side_menu" : ""
          }`}
        >
          <div className="side_menu_header d-flex  justify-content-around align-items-center Border ">
            <h5 className="text-light m-0">Chat Request</h5>
            <div className="chip position-relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="white "
                className="bi bi-chat-left-dots-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
              </svg>
              {chatRequests.length > 0 ? (
                <span className="position-absolute top-0 stat-100 translate-middle badge rounded-pill bg-danger">
                  {chatRequests.length || 0}
                  <span className="visually-hidden">unread messages</span>
                </span>
              ) : null}
            </div>
          </div>
          <div className="req_sec flex-grow-1 overflow-auto min-vh-5 Scroll Border">
            {chatRequests == 0 ? (
              <>
                <img src={No_Chat_Request_img} alt="" srcSet="" />
                <h3>No Request yet !</h3>
              </>
            ) : (
              Array.isArray(chatRequests) &&
              chatRequests.map(
                (request, i) =>
                  !request.accepted && (
                    <ChatRequestCard
                      key={i}
                      acceptChatRequest={() =>
                        acceptChatRequest(request.roomid)
                      }
                      senderName={request.senderName}
                    />
                  )
              )
            )}
          </div>
          <div className="side_menu_footer Border p-2 px-4 d-flex justify-content-between align-items-center">
            <h5 className="text-light">
              {data ? `${data.firstname} ${data.lastname}` : "User"}
            </h5>
            {data ? (
              <span className="profile_user">
                <img src={data.profile_photo} alt="profile" className="" />
              </span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="white"
                className="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fillRule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
            )}
          </div>
        </div>

        <div className="minimise_sideMenu Border" onClick={Toggle_Side_menu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="white"
            className="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
          </svg>
        </div>

        <div className="chat_section vw-100 vh-100 d-flex flex-column">
          <div className="chat_box_header d-flex  justify-content-between align-items-center Border">
            <div className="Chat_details position-relative">
              <span className="avtar1 text-light rounded-circle d-block Border ">
                {data && data.firstname
                  ? `${data.firstname[0].toUpperCase()}${data.lastname[0].toUpperCase()}`
                  : "SU"}
                <span className="visually-hidden">New alerts</span>
              </span>
              {chatReqAccepted ? (
                <span className="avtar2 position-absolute top-50 start-100 translate-middle text-light rounded-circle"></span>
              ) : null}
            </div>
            <div className="end_chat">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="white"
                className="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
                onClick={handleEndChat}
              >
                <path
                  fillRule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                />
              </svg>
            </div>
          </div>
          <div
            id="main_chat_box"
            className="main_chat_box flex-grow-1 overflow-auto d-flex flex-column Scroll m-3"
          >
            <div id="chat_box">
              {messages && messages.length > 0
                ? messages.map((x) => {
                    if (x.role === "Support") {
                      return (
                        <div className="chat-list">
                          <span className="chat-list-message" maxWidth={{maxWidth:"400px"}}>
                            {" "}
                            <h5 className="chat-message"> {x.msg} </h5>
                            {x.attachment && (
                              <img
                                src={x.attachment}
                                style={{
                                  marginBottom: "1rem",
                                  cursor: "pointer",
                                }}
                                className="img-fluid"
                                alt="attachment"
                                onClick={handleOpenImg}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              />
                            )}
                            <p className="chat-time">{x.time}</p>
                            <span className="chatbot__arrow chatbot__arrow--right"></span>
                          </span>

                          <img className="user-img-chat" src={x.profile} />
                        </div>
                      );
                    } else if (x.role === "Advertiser") {
                      return (
                        <div className="chat-list-admin ">
                          <img className="admin-img-chat" src={x.profile} />
                          <span className="chat-list-message  admin-chat-msg" style={{maxWidth: "400px"}}>
                            {" "}
                            <h5 className="chat-message"> {x.msg} </h5>
                            {x.attachment && (
                              <img
                                src={x.attachment}
                                style={{
                                  marginBottom: "1rem",
                                  cursor: "pointer",
                                }}
                                alt="attachment"
                                className="img-fluid"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={handleOpenImg}
                              />
                            )}
                            <p className="chat-time">{x.time}</p>
                            <span className="chatbot__arrow chatbot__arrow--left"></span>
                          </span>
                        </div>
                      );
                    }
                  })
                : null}
            </div>
          </div>
          <div className="send_msg_sec d-flex ">
            <label htmlFor="fileInput" className="d-inline-block p-2 Border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#C01F25"
                className="bi bi-paperclip"
                viewBox="0 0 16 16"
              >
                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
              </svg>
            </label>
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              className="d-none "
              onChange={handleSendAttachment}
            />
            <input
              type="text"
              placeholder={attachment ? "Send file" : "Type a Message"}
              className="Border"
              value={inputValue}
              disabled={!chatReqAccepted}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <button className="Border" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ChatRequestCard = ({ acceptChatRequest, senderName }) => {
  return (
    <div className="request_card container-fluid d-flex justify-content-around p-2 m-3 align-items-center w-auto Border">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#c01f25"
        className="bi bi-chat-left-dots svg-responsive"
        viewBox="0 0 16 16"
      >
        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
      </svg>
      <p className="text-light m-0 p-2 text-truncate text-responsive">
        {senderName} Request
      </p>
      <button
        onClick={acceptChatRequest}
        className="btn btn-sm text-light button-responsive"
      >
        Accept
      </button>
    </div>
  );
};

export default MyMessageAdmin2;
