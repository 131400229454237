import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../../wsContext/WebSocketContext";
import { couponsList, isToaster } from "../../actions";

const MyMessage = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  let token = localStorage.getItem("userToken");
  const data = useSelector((state) => state.user_data);
  const [inputMessage, setInputMessage] = useState("");
  const [roomId, setRoomId] = useState(
    JSON.parse(localStorage.getItem("roomId"))
  );
  const [attachment, setAttachment] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  // const [blobUrl, setBlobUrl] = useState(null);
  const [attachmentLoading,setAttachmentLoading]=useState(false)
  const [messages, setMessages] = useState(
    JSON.parse(localStorage.getItem("chat")) || []
  );
  const [showImg, setShowImg] = useState(null);
  const socket = useWebSocket();
  const [supportId, setSupportId] = useState(
    JSON.parse(localStorage.getItem("chat_acceptedBy_id"))
  );
  const [roomSize,setRoomSize]=useState(null)

  // console.log(roomId);
  // console.log(supportId);
  useEffect(() => {
    if (roomId) {
      const handleUpload = () => {
        socket.emit("end-chat", {
          roomId: roomId,
          joinRoom: "Support-room",
          user: true,
        });
        setRoomId("");
        localStorage.removeItem("roomId");
      };
      window.addEventListener("unload", handleUpload);
      return () => {
        window.removeEventListener("unload", handleUpload);
      };
    }
  }, [roomId, socket]);

  useEffect(() => {
    if (!socket) return;

    socket.emit("joinRoom", {
      user: data.id,
      roomName: "User-room",
    });

    socket.on("message", (incomingMessage) => {
      setMessages((prevMessages) => [...prevMessages, incomingMessage]);
    });

    socket.on("chat-accepted", ({ chatAccepted, roomid, acceptedById }) => {
      console.log(acceptedById);

      if (chatAccepted && acceptedById) {
        setRoomId(roomid);
        setSupportId(acceptedById);
        localStorage.setItem("roomId", JSON.stringify(roomid));
        localStorage.setItem(
          "chat_acceptedBy_id",
          JSON.stringify(acceptedById)
        );
      }
    });

    socket.on("end-chat", ({ message }) => {
      const currentTime = new Date();
      let hr = currentTime.getHours();
      const min = currentTime.getMinutes().toString().padStart(2, '0');
      
      // Determine AM or PM
      const ampm = hr >= 12 ? 'PM' : 'AM';
  
      // Convert hour to 12-hour format
      hr = hr % 12;
      hr = hr ? hr : 12; 
  
      const timeFormatted = `${hr}:${min} ${ampm}`;
      let endChatMsg = {
        msg: message,
        className: "end-chat-msg",
        role: "Support",
        profile: "none",
        time: timeFormatted,
      };
      dispatch(isToaster({ flag: true, msg: `${message} ${endChatMsg.time}` }));
      setRoomId("");
      // setMessages("")
      localStorage.removeItem("roomId");
      localStorage.removeItem("chat_acceptedBy_id");
    });

    socket.on("chat-request-failed", ({ message,roomSize }) => {
      console.log(roomSize)
      setRoomSize(roomSize)
      // console.log(message)
      dispatch(isToaster({ flag: true, msg: message }));
    });

    return () => {
      socket.off("message");
      socket.off("chat-accepted");
      socket.off("end-chat");
      socket.off("chat-request-failed");
    };
  }, [socket, data.id, dispatch]);



  const handleSendAttachment = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Set the file as an attachment
      setAttachment(file);
      // setBlobUrl(URL.createObjectURL(file))
      // console.log("Selected file:", file);
    }
  };
  useEffect(() => {}, []);

  const onSend = async () => {
    if (inputMessage.trim() || attachment) {
      const currentTime = new Date();
      let hr = currentTime.getHours();
      const min = currentTime.getMinutes().toString().padStart(2, '0');
      
      const ampm = hr >= 12 ? 'PM' : 'AM';
  

      hr = hr % 12;
      hr = hr ? hr : 12; 
  
      const timeFormatted = `${hr}:${min} ${ampm}`;

      // Handle file upload
      if (attachment) {
        setAttachmentLoading(true)
        const formData = new FormData();
        formData.append("message", null);
        formData.append("sender_id", data.id);
        formData.append("upload_document", attachment);


        try {
          const response = await fetch(
            "https://socialmedia.quickvisit.me/api/reciever/message",
            {
              method: "POST",
              headers: { Authorization: `Bearer ${token}` },
              body: formData,
            }
          );
          if (response.ok) {
            const result = await response.json();
            console.log("File uploaded successfully:", result);
            setAttachmentUrl(result.attachment);
            let msg = {
              rooid: data.id,
              role: data.role,
              firstname: data.firstname,
              msg: inputMessage,
              senderId: data.id,
              profile: data.profile_photo,
              time: timeFormatted,
              attachment: result.attachment,
            };

            // Emit message via socket
            if (!roomId) {
              socket.emit("request-from-user", {
                SupportRroom: "Support-room",
                roomid: data.id,
                message: { [data.id]: msg },
                senderName: data.firstname,
                read: false,
              });
              setMessages((prevMessages) => [...prevMessages, msg]);
              setAttachmentLoading(false)
            } else if (roomId) {
              socket.emit("message", { roomid: roomId, message: msg });
              setAttachmentLoading(false)
            }
          } else {
            const errorText = await response.text();
            console.error(
              "Failed to upload file. Status:",
              response.status,
              "Status Text:",
              response.statusText,
              "Response Body:",
              errorText
            );
          }
        } catch (error) {
          console.error("Error during file upload:", error);
        }
      } else {
        let msg = {
          rooid: data.id,
          role: data.role,
          firstname: data.firstname,
          msg: inputMessage,
          senderId: data.id,
          profile: data.profile_photo,
          time: timeFormatted,
          attachment: null,
        };

        // Emit message via socket
        if (!roomId) {
          socket.emit("request-from-user", {
            SupportRroom: "Support-room",
            roomid: data.id,
            message: { [data.id]: msg },
            senderName: data.firstname,
            read: false,
          });
          setMessages((prevMessages) => [...prevMessages, msg]);
        } else if (roomId) {
          socket.emit("message", { roomid: roomId, message: msg });
        }
      }

      // Clear input and attachment
      setInputMessage("");
      setAttachment(null);
    }
  };

  const handleKeyDown = (event) => {
    console.log('button clicked ')
    if (event.key === "Enter") {
      onSend();
    }
  };

  useEffect(() => {}, []);
  useEffect(() => {
    localStorage.setItem("chat", JSON.stringify(messages));
    const item = document.getElementById("chat_box");
    if (item) {
      item.scrollTop = item.scrollHeight;
    }
  }, [messages]);


  const handleOpenImg = (e) => {
    console.log(e.target.src);
    setShowImg(e.target.src);
  };


  return (
    <>
      <div
        className="modal fade .bg-dark-subtle"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content .bg-dark-subtle"
            style={{ width: "fit-content", backgroundColor: "#393939" }}
          >
            <div className="modal-header border-bottom border-danger">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img src={showImg} alt="" srcSet="" className="img-fluid"  />
            </div>
            <div className="modal-footer border-top border-danger">
              <button
                type="button"
                className="btn btn- bg-danger text-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="addash">
        <div className="container-fluid">
          <div className="chat-bot-container">
            <div id="chat_box" className="chat-bot-box">
              {messages && messages.length > 0 ? (
                <div className="chat">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={
                        msg.role === "Advertiser"
                          ? "chat-list"
                          : "chat-list-admin"
                      }
                    >
                      {msg.role === "Advertiser" && (
                        <>
                          <span className="chat-list-message" style={{maxWidth:"400px"}}>
                            <h5 className="chat-message">{msg.msg}</h5>

                            {msg.attachment && (
                              <img
                                src={msg.attachment}
                                style={{
                                  // maxWidth: "400px" ,
                                  marginBottom: "1rem",
                                  cursor: "pointer",
                                  maxHeight:"350px"
                                }}
                                className="img-fluid"
                                alt="attachment"
                                onClick={handleOpenImg}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              />
                            )}
                            <p className="chat-time">{msg.time}</p>
                            <span className="chatbot__arrow chatbot__arrow--right"></span>
                          </span>
                          <img
                            className="user-img-chat"
                            src={msg.profile}
                            alt="User"
                          />
                        </>
                      )}
                      {msg.role === "Support" && (
                        <>
                          <img
                            className="admin-img-chat"
                            src={msg.profile}
                            alt="Support"
                          />
                          <span className="chat-list-message" style={{maxWidth:"300px"}}>
                            <h5 className="chat-message">{msg.msg}</h5>
                            {msg.attachment && (
                              <img
                                src={msg.attachment}
                                style={{
                                  // maxWidth: "300px",
                                  marginBottom: "1rem",
                                  cursor: "pointer",
                                  // maxWidth: "400px" 
                                }}
                                className="img-fluid"
                                alt="attachment"
                                onClick={handleOpenImg}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              />
                            )}
                            <p className="chat-time">{msg.time}</p>
                            <span className="chatbot__arrow chatbot__arrow--left"></span>
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>

            <span className="position-relative d-flex  ">
              <label
                htmlFor="fileInput"
                className="d-inline-block p-2  "
                style={{
                  border: "1px solid #c01f25",
                  backgroundColor: "#171717",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#C01F25"
                  className="bi bi-paperclip"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                </svg>
              </label>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="d-none  "
                onChange={handleSendAttachment}
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                name="inputMessage"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={attachment ? "Send file" : "Type a Message"}
                id="myMessage-input"
                className="form-control form-control-ctm snd-msg-inpt"
                style={{
                  borderLeft: 0,
                  borderRadius: 0,
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
              />
              <i
                className="fa fa-paper-plane position-absolute mt-1 end-0 send-msg-btn"
                style={{
                  lineHeight: " 1.3 ",
                  borderRadius: 0,
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
                onClick={onSend}
              ></i>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyMessage;
