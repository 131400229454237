import React, { useEffect, useState } from "react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import $, { Callbacks } from "jquery";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { isLoader, isToaster } from "../../actions";
import { useDispatch } from "react-redux";
import ConfettiExplosion from "react-confetti-explosion";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [plans, setPlans] = useState("");
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const signupDetails = localStorage.getItem("accountToCreate");
  const [isExploding, setIsExploding] = React.useState(false);
  const [dimensions, setDimensions] = useState({ width: 80, height: 0 });
  const [paymentTrackId, setPaymentTrackId] = useState(null);
  const [isCouponApplied, setIsCouponApplied] = useState({
    flag: false,
    code: "",
    amount: 0,
  });
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  // useEffect(() => {
  //   const storedCountry = localStorage.getItem("country");
  //   const storedState = localStorage.getItem("state");
  //   const storedCity = localStorage.getItem("city");
  //   console.log(storedCountry,storedCity,storedState)

  //   if (storedCountry) {
  //     setCountry(storedCountry);
  //   }
  //   if (storedState) {
  //     setState(storedState);
  //   }
  //   if (storedCity) {
  //     setCity(storedCity);
  //   }
  // }, []);

  console.log(country, state, city);
  const onCreateAccount = () => {
    const postData = JSON.parse(signupDetails);
    console.log(postData);
    postData.plan = selectedPlan.type;
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/register`,
        postData,
        customConfig
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          navigate("/login");
          dispatch(
            isToaster({
              flag: true,
              msg: `Hi ${postData.firstname}, Your account is created Successfully!`,
            })
          );
          localStorage.removeItem("accountToCreate");
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(isToaster({ flag: true, msg: err.response.data.message }));
      });
  };

  const haveCoupon = () => {
    $("#js-promo-box").removeClass("d-none");
  };

  const getPlans = () => {
    dispatch(isLoader(true));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-plans`)
      .then((Res) => {
        dispatch(isLoader(false));
        setPlans(Res.data.plans);
        setSelectedPlan(Res.data.plans[0]);
        // dispatch(adData(Res.data.allAds.data));
        console.log("plans : ", Res);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        // console.log(err);
      });
  };

  const createFreeAccount = () => {
    const postData = JSON.parse(signupDetails);
    postData.plan = selectedPlan.type;
    localStorage.setItem("accountToCreate", JSON.stringify(postData));
    navigate(`/signup?step=4&success=0`);
  };

  const proceedToPay = async () => {
    // Retrieve and parse data from localStorage
    const postData = JSON.parse(localStorage.getItem("accountToCreate"));
    console.log(postData);

    if (!postData) {
      console.error("No account data found in localStorage");
      return;
    }

    console.log(selectedPlan);

    // Set the plan type in the postData
    postData.plan = selectedPlan.type;
    localStorage.setItem("accountToCreate", JSON.stringify(postData));
    console.log(postData);

    try {
      // Make the request to the payment API
      const response = await fetch("https://api.oxapay.com/merchants/request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // merchant: "2HPULC-LDBD60-9DL1MW-APL1BD",
          merchant: "sandbox",
          amount:
            selectedPlan.amount -
            (isCouponApplied ? isCouponApplied.amount : 0),
          currency: "USD",
          email: postData.email,
          lifeTime: 15,
          feePaidByPayer: 0,
          underPaidCover: 0,
          returnUrl: `${process.env.REACT_APP_HOME_URL}`,
          // returnUrl:"http://localhost:3000/payment-details",
          description: `${selectedPlan}`,
          orderId: "ORD-12345",
          CallbackUrl: `https://socialmedia.quickvisit.me/api/get/trackbyid/${paymentTrackId}`,
        }),
      });

      // Check for response errors
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const responseData = await response.json();

      // Check if the response contains required fields
      if (responseData && responseData.payLink && responseData.trackId) {
        console.log("Payment Link:", responseData.payLink);
        console.log("Track ID:", responseData.trackId);
        localStorage.setItem("trackId", responseData.trackId);
        console.log(responseData);

        // Set the payment track ID
        setPaymentTrackId(responseData.trackId);

        // Redirect to the payment link
        // window.open(responseData.payLink)
        window.location.href = responseData.payLink;
      } else {
        console.error("Invalid response data:", responseData);
      }
    } catch (error) {
      // Handle errors
      console.error("Error creating payment link:", error);
    }
  };

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth / 2,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("accountToCreate")) {
      navigate("/");
    }
    getPlans();
  }, []);


  const checkCoupon = () => {
    setCouponLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/check-coupon/${coupon}`)
      .then((res) => {
        setCouponLoading(false);
        if (typeof res.data.coupons === "object") {
          if (res.data.coupons.plan === selectedPlan.type) {
            let discount =
              res.data.coupons.discount_type === "fixed"
                ? res.data.coupons.amount
                : (selectedPlan.amount / 100) * res.data.coupons.amount;
            setIsCouponApplied({
              flag: true,
              code: res.data.coupons.coupon_code,
              amount: discount,
            });
          } else {
            dispatch(
              isToaster({ flag: true, msg: "No matching coupon found." })
            );
          }
        } else {
          dispatch(isToaster({ flag: true, msg: res.data.coupons }));
          setIsCouponApplied({ flag: false, code: "", amount: 0 });
        }
      })
      .catch((err) => {
        setCouponLoading(false);
        setIsCouponApplied({ flag: false, code: "", amount: 0 });
        console.log(err);
      });
  };




  return (
    <>
      <Header />
      <section id="payment-page-signup">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="singinform">
                <h4>
                  CHOOSE A <span>PLAN</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row plan-info-row">
            <div className="col-lg-8 col-md-7">
              <ul
                className="nav nav-pills s-payment-section"
                id="pills-tab"
                role="tablist"
              >
                {plans
                  ? plans.map((x) => {
                      return (
                        <li
                          key={x.type} // Added key for React list rendering
                          className="nav-item"
                          role="presentation"
                          onClick={() => {
                            setSelectedPlan(x);
                            setIsCouponApplied({
                              flag: false,
                              code: "",
                              amount: 0,
                            });
                            setCoupon("");
                          }}
                        >
                          <p
                            className={`nav-link position-relative s-payment-op1 m-0 rounded ${
                              x.type === selectedPlan.type ? "active" : null
                            }`}
                            id={`pills-${x.type.toLowerCase()}-tab`}
                            data-toggle="pill"
                            data-target={`#pills-${x.type.toLowerCase()}`}
                            type="button"
                            role="tab"
                            aria-controls={`pills-${x.type.toLowerCase()}`}
                            aria-selected={x.type === selectedPlan.type}
                          >
                            <div className="payment-type s-single_price_header">
                              <div className="types">
                                <div className="text">
                                  <h4>{x.type}</h4>
                                </div>
                                <div className="price">
                                  <div>
                                    <h4 className="m-0">
                                      {x.amount ? x.amount : 0} USD
                                    </h4>
                                    <span>/ Month</span>
                                  </div>
                                  <div className="line"></div>
                                </div>
                              </div>
                            </div>
                          </p>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
            <div className="col-lg-4 col-md-5">
              <div
                className="tab-content s-payment-info-section"
                id="pills-tabContent"
              >
                <div
                  className={`tab-pane fade show active ${
                    selectedPlan.length !== 0 ? null : "d-none"
                  }`}
                  id="pills-free"
                  role="tabpanel"
                  aria-labelledby="pills-free-tab"
                >
                  <div className="s-payment-info-title">
                    <h4>{selectedPlan.type} plan Information</h4>
                  </div>
                  <div className="plan-description">
                    <p>
                      <i className="fa-regular fa-circle-check"></i>Add{" "}
                      {selectedPlan.total_photos} Photos
                    </p>
                    <p>
                      <i className="fa-regular fa-circle-check"></i>Add{" "}
                      {selectedPlan.total_videos} Videos
                    </p>
                    <p>
                      <i className="fa-regular fa-circle-xmark lni-close"></i>No
                      Hidden Fees
                    </p>
                    <p>
                      <i className="fa-regular fa-circle-xmark lni-close"></i>No
                      Hidden Fees
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row billing-detail-row">
            <div className="col-lg-8">
              <div
                className={`mt-4 ${
                  selectedPlan.type === "Free" ? "d-none" : null
                }`}
              >
                <div className="w-100 mt-4 ">
                  <div className="coupon-code mx-auto">
                    <div className="promotion_code">
                      <p
                        id="js-apply-promo"
                        className="m-1"
                        onClick={haveCoupon}
                      >
                        <i class="fa fa-ticket"></i> I have a promo code
                      </p>
                      <div id="js-promo-box" class="promo-box d-none">
                        <input
                          type="text"
                          disabled={isCouponApplied.flag}
                          name="coupon"
                          value={coupon}
                          onChange={(e) => setCoupon(e.target.value)}
                          placeholder="Enter promo code"
                          className="form-control form-control-ctm "
                        />
                        <button
                          className={`btn cus-btn ${
                            isCouponApplied.flag ? "d-none" : "d-block"
                          }`}
                          disabled={couponLoading}
                          onClick={checkCoupon}
                        >
                          Apply
                          {couponLoading ? (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                        </button>
                        <i
                          class={`fa-regular fa-circle-check ${
                            isCouponApplied.flag ? "d-block" : "d-none"
                          }`}
                        ></i>
                        {isExploding && (
                          <ConfettiExplosion
                            style={{
                              zIndex: "9",
                            }}
                            colors={[
                              "#C01F25",
                              "#C01F25",
                              "#FFC700",
                              "#FF0000",
                              "#2E3191",
                              "#41BBC7",
                            ]}
                            onComplete={() => setIsExploding(false)}
                            force={0.4}
                            duration={2200}
                            particleCount={200}
                            width={1000}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="biling-detail-bd mt-4">
                  <div className="billing-detail">
                    <h4 className="text-center ">
                      <span>Billing</span> Detail
                    </h4>

                    <div className="billing-detail-list">
                      <div className="d-flex justify-content-between">
                        <div>Your Plan</div>
                        <div className="biling-amount">{selectedPlan.type}</div>
                      </div>
                    </div>

                    <div className="billing-detail-list">
                      <div className="d-flex justify-content-between">
                        <div>Prize</div>
                        <div className="biling-amount">
                          {selectedPlan.amount} USD
                        </div>
                      </div>
                    </div>

                    <div
                      className={`billing-detail-list ${
                        isCouponApplied.flag ? null : "d-none"
                      }`}
                    >
                      <div className="d-flex justify-content-between">
                        <div>Coupon ({isCouponApplied.code}) applied</div>
                        <div className="biling-amount">
                          -{isCouponApplied.amount} USD
                        </div>
                      </div>
                    </div>

                    <div className="billing-detail-list">
                      <div className="d-flex justify-content-between">
                        <div>To Pay</div>
                        <div className="biling-amount">
                          {selectedPlan.amount - isCouponApplied.amount} USD
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 text-center">
                      <button
                        type="submit"
                        className="btn cus-btn"
                        onClick={proceedToPay}
                      >
                        Proceed to Pay
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`mt-5 text-center  ${
                  selectedPlan.type !== "Free" ? "d-none" : null
                }`}
              >
                {" "}
                <button
                  type="submit"
                  className="btn cus-btn"
                  disabled={loading}
                  onClick={createFreeAccount}
                  // onClick={onCreateAccount}
                >
                  Create Account &nbsp;
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>{" "}
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Payment;
