/** @format */
 
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import forgotpwd from "../../asset/img/forgotpwd.png"
import Footer from "../../component/footer"
import Header from "../../component/header"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { isToaster } from "../../actions"
import { useDispatch } from "react-redux"
 
const ResetPassword = () => {
  const [pwd, setpwd] = useState("")
  const [cpwd, setcpwd] = useState("")
  const [error, setError] = useState("")
  const [show, setShow] = useState('')
  const [token, setToken] = useState("")
  let navigate = useNavigate()
const dispatch = useDispatch()
 
  const onProceed = () => {
    if (pwd === cpwd && pwd.length >= 6) {
      let postData = {
        token: token,
        password: pwd,
        password_confirmation: cpwd,
      }
      setError("")
      console.log(pwd + " " + cpwd)
      axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, postData)
      .then((res) => {
        dispatch(
          isToaster({
            flag: true,
            msg: "Your account Password is Successfully changed!",
          })
        );
        // alert("Your account Password is Successfully changed!")
        navigate("/login")
      }).catch((err) => {
        console.log(err)
      })
    } else if (pwd === cpwd && pwd.length < 6) {
      setError("short")
    } else {
      setError("no-match")
    }
  }
  useEffect(() => {
   
    let url_string = window.location.href
    let url = new URL(url_string)
    let Token = url.searchParams.get("token")
    console.log(Token,"token")
    let postData = {
      token: Token,
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/is-token-valid`, postData)
      .then((res) => {
        console.log(res)
        if (res?.data?.status) {
          setToken(Token)
          setShow("valid")
        } else {
          setShow("exp")
        }
      })
      .catch((err) => {
        setShow("exp")
        console.log(err)
      })
  }, [])
  return (
    <>
      <Header />
      <section>
        <div className={`${show === "valid"? "d-block" : "d-none"} container `}>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="form-layout">
                <div className="row p-3 d-flex align-items-center">
                  <div className="col-lg-6">
                    <img src={forgotpwd} alt="sing up" className="img-fluid" />
                  </div>
                  <div className="col-lg-6">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <div>
                        <div className="singinform p-lg-4">
                          <h4>
                            RESET <span>PASSWORD</span>
                          </h4>
                          <div className="passwordPage mt-3">
                            <div
                              className={`${
                                error === "no-match" ? "d-block" : "d-none"
                              } text-center -mb-3`}
                            >
                              Make sure Password & confirm Password should
                              match!
                            </div>
                            <div
                              className={`${
                                error === "short" ? "d-block" : "d-none"
                              } text-center -mb-3`}
                            >
                              Make sure Password has minimum length of 6
                              character!
                            </div>
                            <div className="input-label">
                              <input
                                type="password"
                                placeholder="New Password"
                                name="pwd"
                                value={pwd}
                                onChange={(e) => setpwd(e.target.value)}
                                className="form-control form-control-ctm "
                              />
                              <span className="pwdeye">
                                <i className="fa fa-eye"></i>
                                {/* <i className="fa fa-eye-slash"></i> */}
                              </span>
                            </div>
                          </div>
                          <div className="passwordPage mt-3">
                            <div className="input-label">
                              <input
                                type="password"
                                placeholder="Confirm Password"
                                name="cpwd"
                                value={cpwd}
                                onChange={(e) => setcpwd(e.target.value)}
                                className="form-control form-control-ctm "
                              />
                              <span className="pwdeye">
                                <i className="fa fa-eye"></i>
                                {/* <i className="fa fa-eye-slash"></i> */}
                              </span>
                            </div>
                          </div>
                          <div className="mt-3">
                            <button
                              type="submit"
                              className="btn sub-btn w-100"
                              onClick={onProceed}
                            >
                              Proceed
                            </button>
                          </div>
                          <p className="mt-2">
                            Don't have an account.{" "}
                            <Link to="/signup" className="login-register">
                              {" "}
                              Sign Up
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
 
        {/* If Token is not valid */}
 
        <div
          className={`${show === "exp"? "d-block" : "d-none"} container text-center p-5`}
        >
          Reset Link Expired
        </div>
      </section>
      <Footer />
    </>
  )
}
 
export default ResetPassword
