/** @format */

import axios from "axios";
import React, { useEffect, useState } from "react";
import no_data from "../../../asset/img/no_data.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { adData, isLoader } from "../../../actions";

const TotalAds = () => {
  const token = localStorage.getItem("userToken");
  let dispatch = useDispatch();
  const ads = useSelector((state) => state.ad_data);
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-all-advertisements?searchText=${search}&page=`;
  const role = localStorage.getItem("userRole");

  const onViewAd = (a) => {
    localStorage.setItem("viewAdId", a.id);
    localStorage.setItem("viewAdvertiser", a.advertiser_id);
    // dispatch(viewAdData(a))
  };

  useEffect(() => {
    dispatch(adData([]));
    dispatch(isLoader(true));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-all-advertisements?page=1`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        dispatch(adData(res.data.allAds.data));
        console.log("Get all Adevertisers : ", res.data.allAds);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    dispatch(isLoader(true));

    axios
      .get(BASE_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        dispatch(adData(res.data.allAds.data));
        console.log("Get all Admins : ", res.data.allAds);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const updateData = (url) => {
    dispatch(isLoader(true));
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        dispatch(adData(res.data.allAds.data));
        console.log("Get all Admins : ", res.data.users);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between ">
                <span>
                  <h4>Total Ads</h4>
                </span>
                <span className="position-relative">
                  <input
                    type="text"
                    placeholder="Search"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    className="form-control form-control-ctm "
                  />
                  <i
                    className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass pointer"
                    onClick={onSearch}
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-3">
              <div className="dashboardtable">
                <div
                  className={`table-scrollable ${
                    data > 1
                      ? "border-radius-n-pagination"
                      : "border-radius-w-pagination"
                  }`}
                >
                  <table className="table table-color table-hover table-fixed-first-columns">
                    <thead>
                      <tr>
                        <th>Ads Name</th>
                        <th>Profile Name</th>
                        <th>Publish Date</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        {/* <th>Active</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(ads) && ads.length != 0 ? (
                        ads.map((x) => {
                          return (
                            <tr>
                              <td>{x.ad_name}</td>
                              <td>{x.advertiser_name}</td>
                              <td>{x.publish_date}</td>
                              <td>{x.location}</td>
                              <td>{x.state}</td>
                              <td>{x.country}</td>
                              {/* <td>
                                  {(x.deleted_at != null) & (x.paused === 0)
                                    ? "TRASHED"
                                    : null}{" "}
                                  {(x.paused === 1) & (x.deleted_at == null)
                                    ? "PAUSED"
                                    : null}{" "}
                                  {(x.deleted_at != null) & (x.paused === 1)
                                    ? "BOTH"
                                    : null}{" "}
                                  {(x.deleted_at == null) & (x.paused === 0)
                                    ? "ACTIVE"
                                    : null}
                                </td> */}
                              {/* <td style={{color :x.expired === "0" & x.paused === 0 ? 'white' : '#c01f25' }}>{x.expired === "0" & x.paused === 0 ? 'YES' : 'NO'}</td> */}
                              <td className="table-action">
                                <div className="d-flex justify-content-center align-items-center">
                                  {/* <span>
                                      <i className="fa-regular fa-pen-to-square"></i>
                                    </span>
                                    &nbsp; &nbsp; */}
                                  <span>
                                    {role === "Super_Admin" ||
                                    role === "Manager" ? (
                                      <Link
                                        to="/admin/viewAds"
                                        onClick={() => onViewAd(x)}
                                      >
                                        <i className="fa-regular fa-eye"></i>
                                      </Link>
                                    ) : (
                                      <Link
                                        to="/support/viewAds"
                                        onClick={() => onViewAd(x)}
                                      >
                                        <i className="fa-regular fa-eye"></i>
                                      </Link>
                                    )}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <img
                              src={no_data}
                              alt="Description of the image"
                              width={277}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* pagination code */}

                {data ? (
                  data.last_page > 1 ? (
                    <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                      <div
                        className="pagination-css-pre"
                        onClick={() => updateData(data.prev_page_url)}
                        disabled={data.current_page === 1}
                      >
                        <span aria-hidden="true">
                          <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className={`pagination-css-active`}>
                          {data.current_page <= data.last_page
                            ? data.current_page
                            : data.last_page}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 1
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 1}`)
                          }
                        >
                          {data.current_page + 1}
                        </span>
                        <span
                          className={`pagination-css ${
                            data.current_page <= data.last_page - 2
                              ? "d-block"
                              : "d-none"
                          }`}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.current_page + 2}`)
                          }
                        >
                          {data.current_page + 2}
                        </span>
                        <span className="pagination-css ">{` ... `}</span>
                        <button
                          className="pagination-css "
                          disabled={data.current_page === data.last_page}
                          onClick={() =>
                            updateData(`${BASE_URL}${data.last_page}`)
                          }
                        >{` ${data.last_page}`}</button>
                      </div>
                      <div
                        className="pagination-css-next"
                        onClick={() => updateData(data.next_page_url)}
                        disabled={data.current_page === data.last_page}
                      >
                        <span aria-hidden="true">
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  ) : null
                ) : null}

                {/* pagination code */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TotalAds;
