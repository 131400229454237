import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./asset/css/style.css";
import "./asset/css/buttonstyle.css";
import "./asset/css/loginform.css";
import "./index.css";
import "./input.css";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import { WebSocketProvider } from "./wsContext/WebSocketContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WebSocketProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </WebSocketProvider>
);
