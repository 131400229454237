/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeleteAdvertiserTable from "./DeleteEntry";
import { isLoader } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import no_data from "../../asset/img/no_data.png";
import axios from "axios";

const Advertiser = () => {
  const token = localStorage.getItem("userToken");
  const [search, setSearch] = useState("");
  const BASE_URL = `${process.env.REACT_APP_API_URL}/api/get-latest-advertisers?searchText=${search}&page=`;
  const dispatch = useDispatch();
  const [advertiser, setAdvertiser] = useState();
  const [data, setData] = useState();
  const role = localStorage.getItem("userRole")
 
  const updateData = (url) => {
    dispatch(isLoader(true));
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.allAds);
        setAdvertiser(res.data.allAds.data);
        console.log("Get all Admins : ", res.data.users);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };
  useEffect(() => {
    dispatch(isLoader(true));
    axios
      .get(BASE_URL + "1", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.latestAdvertisers);
        setAdvertiser(res.data.latestAdvertisers.data);
        console.log("Get latest Adevertisers : ", res);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onSearch = () => {
    dispatch(isLoader(true));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/get-latest-advertisers?searchText=${search}&page=1`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch(isLoader(false));
        setData(res.data.latestAdvertisers);
        setAdvertiser(res.data.latestAdvertisers.data);
        console.log("Get all Admins : ", res.data.allAds);
      })
      .catch((err) => {
        dispatch(isLoader(false));
        console.log(err);
      });
  };

  return (
    <div className="row mt-5">
      <div className="col-lg-12">
        <div className="d-flex justify-content-between ">
          <span>
            <h4>Latest Advertisers</h4>
          </span>
          <span className="position-relative">
            <input
              type="text"
              placeholder="Search"
              name="search"
              value={search}
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
              className="form-control form-control-ctm "
            />
            <i
              className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass pointer"
              onClick={onSearch}
            ></i>
          </span>
        </div>
      </div>
      <div className="col-lg-12 mt-3">
        <div className="dashboardtable">
          <div
            className={`table-scrollable ${
              data > 1
                ? "border-radius-n-pagination"
                : "border-radius-w-pagination"
            }`}
          >
            {/* <table className="table table-color table-hover table-fixed-first-columns">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Age</th>
                  <th>Plan</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(advertiser) && advertiser.length != 0 ? (
                  advertiser.map((x) => {
                    return (
                      <tr>
                        <td>{x.firstname + " " + x.lastname}</td>
                        <td>{x.email}</td>
                        <td>{x.phone}</td>
                        <td>{x.age}</td>
                        <td>FREE</td>
                        <td className="table-action">
                          <div className="d-flex justify-content-center align-items-center">
                            
                            &nbsp; &nbsp;
                            <span>
                              {" "}
                              <Link to="/admin/viewAdvertiser">
                                <i
                                  className="fa-regular fa-eye"
                                  onClick={() =>
                                    localStorage.setItem("viewAdvertiser", x.id)
                                  }
                                ></i>
                              </Link>
                            </span>
                            &nbsp; &nbsp;
                            <DeleteAdvertiserTable />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <img
                        src={no_data}
                        alt="Description of the image"
                        width={277}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table> */}
              <table className="table table-color table-hover table-fixed-first-columns">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Age</th>
                  <th>Plan</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(advertiser) && advertiser.length != 0 ? (
                  advertiser.map((x) => {
                    return (
                      <tr>
                        <td>{x.firstname + " " + x.lastname}</td>
                        <td>{x.email}</td>
                        <td>{x.phone}</td>
                        <td>{x.age}</td>
                        <td>{x.plan}</td>
                        <td className="table-action">
                          <div className="d-flex justify-content-center align-items-center">
                           
                            &nbsp; &nbsp;
                            <span>
                              {" "}
                              {role === "Super_Admin" ||
                                role === "Manager" ? (
                              <Link to="/admin/viewAdvertiser">
                                <i
                                  className="fa-regular fa-eye"
                                  onClick={() =>
                                    localStorage.setItem("viewAdvertiser", x.id)
                                  }
                                ></i>
                              </Link>):(
                                <Link to="/support/viewAdvertiser">
                                <i
                                  className="fa-regular fa-eye"
                                  onClick={() =>
                                    localStorage.setItem("viewAdvertiser", x.id)
                                  }
                                ></i>
                              </Link>
                              )}
                            </span>
                            &nbsp; &nbsp;
                            <DeleteAdvertiserTable />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <img
                        src={no_data}
                        alt="Description of the image"
                        width={277}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* pagination code */}

          {data ? (
            data.last_page > 1 ? (
              <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                <div
                  className="pagination-css-pre"
                  onClick={() => updateData(data.prev_page_url)}
                  disabled={data.current_page === 1}
                >
                  <span aria-hidden="true">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="d-flex">
                  <span className={`pagination-css-active`}>
                    {data.current_page <= data.last_page
                      ? data.current_page
                      : data.last_page}
                  </span>
                  <span
                    className={`pagination-css ${
                      data.current_page <= data.last_page - 1
                        ? "d-block"
                        : "d-none"
                    }`}
                    onClick={() =>
                      updateData(`${BASE_URL}${data.current_page + 1}`)
                    }
                  >
                    {data.current_page + 1}
                  </span>
                  <span
                    className={`pagination-css ${
                      data.current_page <= data.last_page - 2
                        ? "d-block"
                        : "d-none"
                    }`}
                    onClick={() =>
                      updateData(`${BASE_URL}${data.current_page + 2}`)
                    }
                  >
                    {data.current_page + 2}
                  </span>
                  <span className="pagination-css ">{` ... `}</span>
                  <button
                    className="pagination-css "
                    disabled={data.current_page === data.last_page}
                    onClick={() => updateData(`${BASE_URL}${data.last_page}`)}
                  >{` ${data.last_page}`}</button>
                </div>
                <div
                  className="pagination-css-next"
                  onClick={() => updateData(data.next_page_url)}
                  disabled={data.current_page === data.last_page}
                >
                  <span aria-hidden="true">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            ) : null
          ) : null}

          {/* pagination code */}
        </div>
      </div>
    </div>
  );
};

export default Advertiser;
