import isSidebar from "./isSidebar";
import advertiser_Side_Tab from "./advertiser_Side_Tab";
import user_data from "./user_data";
import ad_data from "./ad_data";
import show_price_popup from "./show_price_popup";
import show_renew_popup from "./show_renew_popup";
import edit_ad_data from "./edit_ad_data";
import availability_data from "./availability_data";
import show_availability from "./show_availability";
import editable_calendar_data from "./editable_calendar_data";
import show_editable_calendar from "./show_editable_calendar";
import id_to_delete from "./id_to_delete";
import is_loader from "./is_loader";
import is_toaster from "./is_toaster";
import view_ad_data from "./view_ad_data";
import video_id_to_delete from "./video_id_to_delete";
import advertiser_data from "./advertiser_data";

import { combineReducers } from "redux";
import chat_messages from "./chat_messages";
import top_list from "./top_list";
import search_gender from "./search_gender";
import search_city from "./search_city";
import search_age from "./search_age";
import search_eye_color from "./search_eye_color";
import search_hair_color from "./search_hair_color";
import search_height from "./search_height";
import search_body_type from "./search_body_type";
import search_breast_size from "./search_breast_size";
import search_ethnicity from "./search_ethnicity";
import search_availability from "./search_availability";
import premium_list from "./premium_list";
import available_list from "./available_list";
import advertiser_city from "./advertiser_city";
import all_list1 from "./all_list1";
import all_list2 from "./all_list2";
import coupons_list from "./coupons_list";
import promoted_zone from "./promoted_zone";
import available_list_more from "./available_list_more";
import all_advertiser from "./all_advertiser";
import is_join_request from "./is_join_request";
import is_age_confirmed from "./is_age_confirmed";
import advertiser_id from "./advertiser_id";
import advertisement_id from "./advertisement_id";
import available_days from "./available_days";

const rootReducer = combineReducers({
  isSidebar,
  advertiser_Side_Tab,
  user_data,
  ad_data,
  show_price_popup,
  show_renew_popup,
  edit_ad_data,
  availability_data,
  show_availability,
  show_editable_calendar,
  editable_calendar_data,
  id_to_delete,
  is_loader,
  is_toaster,
  view_ad_data,
  video_id_to_delete,
  advertiser_data,
  chat_messages,
  top_list,
  search_gender,
  search_city,
  search_age,
  search_eye_color,
  search_hair_color,
  search_height,
  search_body_type,
  search_breast_size,
  search_ethnicity,
  search_availability,
  premium_list,
  available_list,
  all_list1,
  all_list2,
  available_list_more,
  advertiser_city,
  coupons_list,
  promoted_zone,
  all_advertiser,
  is_join_request,
  is_age_confirmed,
  advertiser_id,
  advertisement_id,
  available_days,
});

export default rootReducer;
