/** @format */
import { Link } from "react-router-dom"
import logo from "../asset/img/logo.png"

function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="container footer_border">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="footer-logo">
                <img src={logo} alt="logo" />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 px-lg-5">
              <div className="footer-menu">
                <h5>
                  Loreum <span>Ipsum</span>
                </h5>
                <ul className="footer-itm-mnu">
               <Link to="/premium">   <li>Premium/VIP</li></Link>
               <Link to="/premium">   <li>Online Services</li></Link>
               <Link to="/premium">    <li>Advanced Search</li></Link>
               <Link to="/faqs">       <li>FAQ</li> </Link>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 px-lg-5">
              <div className="footer-menu">
                <h5>
                  Loreum <span>Ipsum</span>
                </h5>
                <ul className="footer-itm-mnu">
                <Link to="/about-us">  <li>About Us</li></Link>
                <Link to="/blog"> <li>Blog</li> </Link>
                <Link to="/privacy-policy">  <li>Privacy Policy</li></Link>
                <Link to="/terms-of-use"> <li>Term And Conditions</li> </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer-bar">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 col-sm-6">
              <div id="copyright">
                Copyright © <span id="copyright-year"></span>
                <Link
                  style={{ color: "#fff", textDecoration: "underline" }}
                  to="/"
                  target="_blank"
                  rel="noopener"
                >
                   QuickVisit. 
                </Link>
                 &nbsp; All Rights Reserved <Link
                  style={{ color: "#fff", textDecoration: "underline" }}
                  to="/terms-of-use"
                  target="_blank"
                  rel="noopener"
                >
                    Terms and Conditions
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 footer-icon">
              <div className="float-end">
                <i className="fa  fa-facebook navicon mx-1"></i>
                <i className="fa-brands fa-whatsapp navicon mx-1"></i>
                <i className="fa-brands fa-x-twitter navicon mx-1"></i>
                <i className="fa-regular fa-envelope navicon mx-1"></i>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
