/** @format */

import React from "react"
import adrenew from "../../../src/asset/img/adrenew.png"
import { useDispatch } from "react-redux"
import { isLoader, isToaster, showRenewPopup } from "../../actions"
import { useSelector } from "react-redux"
import { adData } from "../../actions"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const RenewAds = (props) => {
  let token = localStorage.getItem("userToken")
  const navigate = useNavigate()
  let dispatch = useDispatch()
  const show = useSelector((s) => s.show_renew_popup)
  const handleOnRenew = async () => {
    dispatch(isLoader(true))
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/renew-advertisement/${props.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch(isToaster({flag: true, msg: 'Ad renewed Successfully!'}))
        dispatch(isLoader(false))
        const customConfig1 = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        navigate("/advertiser/activeAds")
        dispatch(isLoader(true))
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/get-advertisements`,
            customConfig1
          )
          .then((Res) => {
            dispatch(isLoader(false))
            // numberOfPages = Math.floor(
            //   Res.data.allAds.filter((x) => x.expired !== "0").length / 7
            // )
            // if (numberOfPages < addata.length / 7) {
            //   setTotalPage(numberOfPages + 1)
            // } else {
            //   setTotalPage(numberOfPages)
            // }
            dispatch(adData(Res.data.allAds.data))
          })
          .catch((err) => {
            console.log(err)
          })
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
    dispatch(showRenewPopup(false))
  }
  console.log(adData)
  return (
    <>
      <div
        className={`modal_container w-50 text-center m-auto ${
          show ? "d-block" : "d-none"
        }`}
      >
        <div className="modal-dialog">
          <div className="modal-content price-table p-4">
            <div className="position-relative">
              {/* <h2 className="modal-title fs-5 text-body" id="deletepopupLabel">
                Renew Ads
              </h2> */}
              <span className="position-absolute cross_close top-0 end-0">
                <i
                  className="fa-solid fa-x"
                  data-dismiss="modal"
                  onClick={() => dispatch(showRenewPopup(false))}
                ></i>
              </span>
            </div>
            <div className="modal-body renewad">
              <img src={adrenew} alt="AdRenew" className="img-fluid" />
              <h2 className="fs-6 text-body">Are you sure to Renew this Ads</h2>
            </div>

            <div className="p-2">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn cus-btn "
                  onClick={handleOnRenew}
                >
                  Renew Ads
                </button>
                <button
                  type="button"
                  className="btn sec-btn ms-3"
                  onClick={() => dispatch(showRenewPopup(false))}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RenewAds
