import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import age from "../../asset/img/age_confirm.png";
import { useDispatch, useSelector } from "react-redux";
import { isAgeConfirmed } from "../../actions";

const AgeConfirmation = () => {
  const dispatch = useDispatch();
  const [checked, setIsChecked] = useState(false);
  const [isTrue, setIsTrue] = useState(false);


  const isVisible = useSelector((s) => s.is_age_confirmed);
  const [isDeclined, setIsDeclined] = useState(false);
  const check = localStorage.getItem("age18" === "true");
  const navigate = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem("age18")) {
      document.body.classList.add("popup-open");
    }
    if (localStorage.getItem("age18" === "true") || isVisible || isDeclined) {
      document.body.classList.remove("popup-open");
    }
  }, [isVisible]);
  const location = useLocation()
  console.log(location)
const handleNavigate =()=>{
  setIsTrue(true)
  navigate('/terms-of-use')
}
  return (
    <>
      <div
        className={`container ${
          isVisible || localStorage.getItem("age18") === "true" || isTrue|| isDeclined
            ? "d-none"
            : null
        }`}
        style={{
          position: "fixed",
          top: "10vh",
          left: "5.4vw",
          zIndex: "99",
        }}
      >
        <div className="row">
          <div className="age-confrimation-popup">
            <div className="content">
              {" "}
              <div className="modal-dialog">
                <div className="modal-content p-1">
                  <div className="text-center">
                    <img src={age} alt="Age-Confirmation" width={80} />
                    <h4 style={{ color: "#c01f25" }}>
                      Please read the following notice before you continue
                    </h4>
                    <h6>I'm over 18 years old.</h6>
                  </div>

                  <div className="modal-body mt-1">
                    <p>
                      This website contains nudity, explicit sexual content and
                      adult language. It should be accessed only by people who
                      are of legal age in the physical location from where you
                      are accessing the site. By accessing this website, you are
                      representing to us that you are of legal age and agree to
                      our Terms & Conditions. Any unauthorized use of this site
                      may violate state, federal and/or foreign law.
                    </p>

                    <p>
                      I also agree to report suspected exploitation of minors
                      and/or human trafficking to the appropriate authorities.
                    </p>
                    <p className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        value={checked}
                        id="checked"
                        className="pointer  me-1"
                        onChange={() => setIsChecked(!checked)}
                      />{" "}
                      <label for="checked" className="m-0">I have read and agree to this disclaimer as well as the{" "} 
                      <Link to='/terms-of-use'   style={{ color: "#c01f25" }}>
                        Terms of Use.
                      </Link>
                        </label>
                    </p>

                    <div class="p-2 mt-2">
                      <div class="d-flex justify-content-center">
                        <button
                          type="button"
                          disabled={!checked}
                          class="btn cus-btn  "
                          onClick={() =>
                            dispatch(isAgeConfirmed(true)) &
                            localStorage.setItem("age18", "true") &
                            document.body.classList.remove("popup-open")
                          }
                        >
                          Accept
                        </button>
                        <button
                          type="button"
                          class="btn cus-btn"
                          style={{marginLeft:'8px'}}

                          onClick={() => {
                            setIsDeclined(true);
                            window.location.href = "https://www.google.com/";
                          }}
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgeConfirmation;
