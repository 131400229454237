import React, { useEffect, useState } from "react";
import axios from "axios";
import DeleteNotification from "../component/DeleteNotification";

const Notification = () => {
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await axios.get(
          "https://socialmedia.quickvisit.me/api/get/notification"
        );
        setData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotification();
  }, []);

  const handleDelete = (id) => {
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  // Calculate the current notifications to display
  const indexOfLastNotification = currentPage * itemsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - itemsPerPage;
  const currentNotifications = data.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {deleteId && (
        <DeleteNotification
          deleteId={deleteId}
          onDelete={handleDelete}
          onClose={() => setDeleteId("")}
        />
      )}
      <section className="addash">
        <div className="container-fluid">
          <div className="col-lg-12 mt-3">
            <div className="dashboardtable">
              <div
                className={`table-scrollable ${
                  data.length > 1
                    ? "border-radius-n-pagination"
                    : "border-radius-w-pagination"
                }`}
              >
                <table className="table table-color table-hover table-fixed-first-columns">
                  <thead>
                    <tr>
                      <th>Sr no</th>
                      <th>Name</th>
                      <th>Notification</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentNotifications.map((value, index) => (
                      <tr key={value._id}>
                        <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                        <td>{value?.name || "-"}</td>
                        <td>{value?.message}</td>
                        <td>{value?.date}</td>
                        <td>
                          <i
                            className="far fa-trash-alt"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => setDeleteId(value?.id)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="d-flex justify-content-center w-100 mx-auto pt-4 pb-3">
                <button
                  className="pagination-css-pre"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <span aria-hidden="true">
                    <i
                      className="fa fa-chevron-left"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  </span>
                </button>

                {[...Array(Math.ceil(data.length / itemsPerPage)).keys()].map(
                  (number) => (
                    <button
                      key={number + 1}
                      className={`pagination-css `}
                      onClick={() => paginate(number + 1)}
                      style={{ color: currentPage === number + 1 ? "red" : "" }}
                    >
                      {number + 1}
                    </button>
                  )
                )}

                <button
                  className="pagination-css-next"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage === Math.ceil(data.length / itemsPerPage)
                  }
                >
                  <span aria-hidden="true">
                    <i
                      className="fa fa-chevron-right"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  </span>
                </button>
              </div>

              {/* Pagination */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Notification;
