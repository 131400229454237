import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adData, isToaster } from "../../../actions";
import DisplayAvailability from "../../component/DisplayAvailability";
import UpdateAvailability from "../../component/UpdateAvailability";
import {
  GetCountries,
  GetState,
  GetCity,
  // GetLanguages, //async functions
} from "react-country-state-city";

const EditAds = () => {
  let token = localStorage.getItem("userToken");
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [countryid, setCountryid] = useState(-1);
  const [stateid, setStateid] = useState(-1);
  const [cityid, setCityid] = useState(-1);

  const data = JSON.parse(localStorage.getItem("adToEdit"));
  console.log(data, "d");
  const [edata, setEdata] = useState(data);
  const [loading, setLoading] = useState(false);
  const [loadingStopAd, setLoadingStopAd] = useState(false);
  const [priceToAdd, setPriceToAdd] = useState({
    hour: "",
    min: "",
    price: "",
  });

  const handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      const selectedCountry = countriesList.find((country) => country.name === value);
      setCountryid(selectedCountry.id);
      setEdata({ ...edata, country: value, state: "", location: "" }); // Reset state and location
      setStateList([]); // Clear state list
      setCityList([]); // Clear city list
    } else if (name === "state") {
      const selectedState = stateList.find((state) => state.name === value);
      setStateid(selectedState.id);
      setEdata({ ...edata, state: value, location: "" }); // Reset location
      setCityList([]); // Clear city list
    } else if (name === "location") {
      const selectedCity = cityList.find((city) => city.name === value);
      setCityid(selectedCity.id);
      console.log(name,value,"dsdsd")
      setEdata({ ...edata, location: value });
    } else {
      setEdata({ ...edata, [name]: value });
    }
  };

  const handlePrice = (e) => {
    const { name, value } = e.target;
    if (name === "hour") {
      setPriceToAdd({ ...priceToAdd, hour: value });
    } else if (name === "min") {
      setPriceToAdd({ ...priceToAdd, min: value });
    } else if (name === "price") {
      setPriceToAdd({ ...priceToAdd, price: value });
    }
  };

  const locationName = cityList.find((x) => x.id == cityid)?.name || "";
  const stateName = stateList.find((x) => x.id == stateid)?.name || "";
  const countryName =
    countriesList.find((x) => x.id == countryid)?.name || "";

  const onHide = (ID) => {
    const postData = {
      id: ID,
    };
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoadingStopAd(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/expire-advertisement`,
        postData,
        customConfig1
      )
      .then((res) => {
        setLoadingStopAd(false);
        navigate("/advertiser/expiredAds");
        console.log(res);
      })
      .catch((err) => {
        setLoadingStopAd(false);
        console.log(err);
      });
  };

  const handleAddPrice = () => {
    const d = new Date();
    let time = Math.round(d.getTime());
    priceToAdd.id = time;
    if (priceToAdd.hour || priceToAdd.min) {
      if (priceToAdd.price) {
        setEdata({
          ...edata,
          duration_price: [
            ...edata.duration_price,
            {
              hour: priceToAdd.hour,
              min: priceToAdd.min,
              price: priceToAdd.price,
              id: priceToAdd.id,
            },
          ],
        });
        setPriceToAdd({
          hour: "",
          min: "",
          price: "",
        });
      } else {
        dispatch(
          isToaster({ flag: true, msg: "Please enter Amount in Price list" })
        );
      }
    } else {
      dispatch(
        isToaster({ flag: true, msg: "Please enter Price list details" })
      );
    }
  };

  const onUpdate = async () => {
    console.log(edata);
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const customConfig1 = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let postData = {
      ad_name: edata.ad_name,
      email: edata.email,
      phone: edata.phone,
      about_me: edata.about_me,
      publish_date: edata.publish_date,
      location: edata.location,
      mile_radius: edata.mile_radius,
      state: edata.state,
      country: edata.country,
      duration_price: edata.duration_price.map((x) => x),
      availability: [],
    };
    if (
      priceToAdd.hour != "" ||
      priceToAdd.min != "" ||
      priceToAdd.price != ""
    ) {
      dispatch(
        isToaster({
          flag: true,
          msg: "Please add the entered Price details by clicking add button ",
        })
      );
    } else {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/update-advertisement/${data.id}`,
          postData,
          customConfig
        )
        .then((res) => {
          setLoading(false);
          dispatch(isToaster({ flag: true, msg: "Ad updated Successfully!" }));
          console.log("create Ads data : ", res);
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/get-advertisements`,
              customConfig1
            )
            .then((Res) => {
              dispatch(adData(Res.data.allAds.data));
              console.log("get Ads data at editAd : ", Res);
              if (edata.expired === "0") {
                navigate("/advertiser/activeAds");
              } else {
                navigate("/advertiser/expiredAds");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const deleteEntry = (Id) => {
    let arr = edata.duration_price.filter((x) => x.id !== Id);
    setEdata({ ...edata, duration_price: [...arr] });
  };

  window.onload = () => {
    if (typeof data == undefined) {
      navigate("/advertiser/activeAds");
    }
  };

  useEffect(() => {
    if (typeof data != undefined) {
      GetCountries()
        .then((result) => {
          setCountriesList(result);
          const country = result.find((country) => country.name === data.country);
          setCountryid(country.id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (countryid !== -1) {
      GetState(countryid)
        .then((result) => {
          setStateList(result);
          const state = result.find((state) => state.name === data.state);
          setStateid(state.id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [countryid]);

  useEffect(() => {
    if (stateid !== -1) {
      GetCity(countryid, stateid).then((result) => {
        setCityList(result);
        const city = result.find((city) => city.name === data.location);
        setCityid(city?.id);
      });
    }
  }, [countryid, stateid]);

  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>Edit Ads</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="addprofile">
              <div className="row input-label">
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Ad Name</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="ad_name"
                    value={edata.ad_name}
                    onChange={handleInputs}
                    // placeholder="Ad Name"
                    className="form-control form-control-ctm mb-2"
                  />
                </div>

                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Phone Number</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={edata.phone}
                    onChange={handleInputs}
                    // placeholder="Phone Number"
                    className="form-control form-control-ctm mb-2"
                  />
                </div>
              </div>
              <div className="row input-label">
                < div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Email</span>{" "}
                  </label>
                  <input
                    type="email"
                    name="email "
                    value={edata.email}
                    onChange={handleInputs}
                    // placeholder="Email"
                    className="form-control form-control-ctm mb-2"
                  />
                </div>

                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Publish Date</span>{" "}
                  </label>
                  <input
                    disabled
                    type="date"
                    name="publish_date"
                    value={edata.publish_date}
                    onChange={handleInputs}
                    className="form-control form-control-ctm mb-2"
                  />
                </div>
              </div>

              <div className="row input-label">
                <div className="col-lg-12 col-md-12">
                  <label className="mb-1">
                    {" "}
                    <span>About me</span>{" "}
                  </label>
                  <textarea
                    id="about_me"
                    maxlength="150"
                    name="about_me"
                    rows="4"
                    cols="50"
                    value={edata.about_me}
                    onChange={handleInputs}
                    type="text"
                    // placeholder="About Me"
                    className="form-control form-control-ctm"
                  ></textarea>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <h6>Target City </h6>
                </div>
              </div>
              <div className="row input-label mt-2">
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    <span>Country</span>
                  </label>
                  <select
                    className="form-control form-select-ctm mb-2"
                    aria-label="select"
                    name="country"
                    value={edata.country}
                    onChange={handleInputs}
                  >
                    <option value="" disabled>
                      Select a country
                    </option>
                    {countriesList.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className ="col-lg-6 col-md-6">
                  <label className="mb-1">
                    <span>State</span>
                  </label>
                  <select
                    className="form-control form-select-ctm mb-2"
                    aria-label="select"
                    name="state"
                    value={edata.state}
                    onChange={handleInputs}
                  >
                    <option value="" disabled>
                      Select a state
                    </option>
                    {stateList.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row input-label ">
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    <span>City</span>
                  </label>
                  <select
                    className="form-control form-select-ctm mb-2"
                    aria-label="select"
                    name="location"
                    value={edata.location}
                    onChange={handleInputs}
                  >
                    <option value="" disabled>
                      Select a city
                    </option>
                    {cityList.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-6 col-md-6">
                  <label className="mb-1">
                    {" "}
                    <span>Mile Radius </span>{" "}
                  </label>
                  <input
                    type="text"
                    name="mile_radius"
                    value={edata.mile_radius}
                    onChange={handleInputs}
                    // placeholder="Mile Radius"
                    className="form-control form-control-ctm"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <h6>Price</h6>
                </div>
              </div>

              {edata.duration_price
                ? edata.duration_price.map((x) => {
                    return (
                      <div
                        className="row input-label position-relative mb-2"
                        key={x.id}
                      >
                        <div className="col-lg-6 col-md-6">
                          <div className="row">
                            <div className=" col-lg-9 col-md-9">
                              <label className="mb-1">
                                {" "}
                                <span>Duration</span>{" "}
                              </label>
                              <span className="d-flex justify-content-between ">
                                <select
                                  className="form-select-ctm "
                                  aria-label="select"
                                  name="hour"
                                  disabled
                                  value={x.hour}
                                >
                                  <option selected value="00">
                                    00 Hour
                                  </option>
                                  <option value="01">01 Hour</option>
                                  <option value="02">02 Hour</option>
                                  <option value="03">03 Hour</option>
                                  <option value="04">04 Hour</option>
                                  <option value="05">05 Hour</option>
                                  <option value="06">06 Hour</option>
                                  <option value="07">07 Hour</option>
                                  <option value="08">08 Hour</option>
                                </select>
                                <select
                                  className="form-select-ctm add-row-duration"
                                  aria-label="select"
                                  name="min"
                                  disabled
                                  value={x.min}
                                >
                                  <option selected value="00">
                                    00 Minutes
                                  </option>
                                  <option value="15">15 Minutes</option>
                                  <option value="30">30 Minutes</option>
                                  <option value="45">45 Minutes</option>
                                  <option value="60">60 Minutes</option>
                                </select>
                              </span>
                            </div>
                            <div className="col-lg-3 col-lg-3"></div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-9">
                              <div className="add-row-width">
                                <label className="mb-1">
                                  <span>Price</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  name="price"
                                  value={x.price}
                                  disabled
                                  // placeholder="Amount"
                                  className="form-control form-control-ctm"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3">
                              <span
                                className="add-row-btn"
                                onClick={() => deleteEntry(x.id)}
                              >
                                <i className="fa fa-minus"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}

              <div className="row input-label position-relative">
                <div className="col-lg-6 col-md-6">
                  <div className="row">
                    <div className="col-lg-9 col-md-9">
                      <label className="mb-1">
                        {" "}
                        <span>Duration</span>{" "}
                      </label>
                      <span className="d-flex justify-content-between ">
                        <select
                          className="form-select-ctm "
                          aria-label="select"
                          name="hour"
                          value={priceToAdd.hour}
                          onChange={handlePrice}
                        >
                          <option selected value="00">
                            00 Hour
                          </option>
                          <option value="01">01 Hour</option>
                          <option value="02">02 Hour</option>
                          <option value="03">03 Hour</option>
                          <option value="04">04 Hour</option>
                          <option value="05">05 Hour</option>
                          <option value="06">06 Hour</option>
                          <option value="07">07 Hour</option>
                          <option value="08">08 Hour</option>
                        </select>
                        <select
                          className="form-select-ctm add-row-duration"
                          aria-label="select"
                          name="min"
                          value={priceToAdd.min}
                          onChange={handlePrice}
                        >
                          <option selected value="00">
                            00 Minutes
                          </option>
                          <option value="15">15 Minutes</option>
                          <option value="30">30 Minutes</option>
                          <option value="45">45 Minutes</option>
                          <option value="60">60 Minutes</option>
                        </select>
                      </span>
                    </div>
                    <div className="col-lg-3 col-lg-3"></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="row ads-s-phone">
                    <div className="col-lg-9 col-md-9 col-sm-9">
                      <div className="add-row-width">
                        <label className="mb-1">
                          <span>Price</span>{" "}
                        </label>
                        <input
                          type="text"
                          name="price"
                          value={priceToAdd.price}
                          onChange={handlePrice}
                          // placeholder="Amount"
                          className="form-control form-control-ctm"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-3 col-sm-3"
                      onClick={handleAddPrice}
                    >
                      <span className="add-row-btn">
                        <i className="fa-regular fa-plus"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12 p-0">
              <div className="float-end">
                <button
                  type="button"
                  disabled={loadingStopAd}
                  className="btn pri-btn mr-2"
                  onClick={() => onHide(data.id)}
                >
                  Stop Ad&nbsp;
                  {loadingStopAd ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
                <button
                  type="button"
                  className="btn pri-btn"
                  onClick={onUpdate}
                >
                  Update&nbsp;
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditAds;